import React, { FC, useEffect, useState, FormEvent } from 'react';
import Table from '../../Table';
import { Grid, Typography, Dialog, TextField, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Add, Search } from '@material-ui/icons';
import { AddHSuser } from '../../../graphql/mutations';
import { HSuserBasicInfo, GetAllHSusersFilter } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import Button from '../../Button';
import HSaccountForm, { FormFields as HSaccountFormFields } from '../../Form/HSaccountForm';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from '../../../reduxStore/store';
import { getAllHSusers } from '../../../reduxStore/slices/sbqhPortalSlice';
import { ErrorDialog, SuccessDialog } from '../../Alert';
import AccountDetailDialog from './AccountDetailDialog';

const searchFilters: { [P in keyof GetAllHSusersFilter]: string } = {
    id: "ID",
    first_name: "First Name",
    last_name: "Last Name",
    its_no: "Its No"
}

const HS_Accounts: FC = ({ }) => {
    const { data, pageNumber, pageSize, totalCount, filterBy } = useSelector((state: RootStateType) => state.sbqhPortal.hs_users_data)
    const isFetching = useSelector((state: RootStateType) => state.sbqhPortal.fetchingStatus.getAllHSusers)
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [rowDetails, setRowDetails] = useState<HSuserBasicInfo | null>(null);
    const [filterKey, setFilterKey] = useState('id');
    const dispatch = useAppDispatch();

    const loadData = async () => {
        dispatch(getAllHSusers({ pageNumber, pageSize, filterBy }))
    }
    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        dispatch(getAllHSusers({ pageNumber, pageSize, filterBy }))
    }
    const handleSearchByFilter = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const elem = e.currentTarget.elements.item(0) as HTMLInputElement
        // console.log(filterKey)
        // const id = elem.value;
        dispatch(getAllHSusers({ pageNumber, pageSize, filterBy: { [filterKey]: elem.value } }))
    }
    const OpenAccountDetailDialog = (values: HSuserBasicInfo) => {
        setOpenDialog(true);
        setRowDetails(values)
    }

    useEffect(() => {
        !isFetching && !data && loadData()
    }, [])

    return (
        <Grid container direction='column' >
            <br />
            <Grid container justify='space-between' alignItems='center'>
                <Grid container item alignItems='center' xs={8} >
                    <form onSubmit={handleSearchByFilter} >
                        <Search />
                        <TextField name='search' title="Search" placeholder="Search" />
                    </form>
                    <Grid sm={3} item><TextField style={{ marginLeft: '5px' }} value={filterKey} onChange={(e) => { setFilterKey(e.target.value) }} variant="outlined" select type='select' size="small" label='Search By' fullWidth
                        children={Object.entries(searchFilters).map(([key, value], idx) => (
                            <MenuItem key={idx} value={key} >{value}</MenuItem>
                        ))}
                    /></Grid>
                </Grid>
                <AddHSaccountForm />
            </Grid>
            <br />

            {rowDetails &&
                <AccountDetailDialog open={openDialog} handleClose={() => { setOpenDialog(false) }} userId={rowDetails.id} />}

            <Table
                label={<Typography variant='h4' align='center' >Husaini Scheme Accounts</Typography>}
                columns={[
                    { id: "id", align: "left", label: "Account ID", format: (val: string, rowValue) => <p style={{ cursor: "pointer", color: "purple", textDecoration: "underline" }} onClick={() => { OpenAccountDetailDialog(rowValue) }} >{val.toUpperCase()}</p> },
                    { id: "first_name", align: "left", label: "First Name", },
                    { id: "last_name", align: "left", label: "Last Name", },
                    { id: "its_no", align: "left", label: "ITS.No" },
                ]}
                rows={data || []}
                totalRows={totalCount}
                pageNumber={pageNumber - 1}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no accounts to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />
        </Grid>
    )
}

export default HS_Accounts;


export const tableLoading = (<>
    <Skeleton variant='rect' height={48} width="100%" animation='wave' style={{ marginBottom: "2px" }} />
    <Skeleton variant='rect' height={48} width="100%" animation='wave' style={{ marginBottom: "2px" }} />
    <Skeleton variant='rect' height={48} width="100%" animation='wave' style={{ marginBottom: "2px" }} />
    <Skeleton variant='rect' height={48} width="100%" animation='wave' style={{ marginBottom: "2px" }} />
    <Skeleton variant='rect' height={48} width="100%" animation='wave' style={{ marginBottom: "2px" }} />
</>)


const AddHSaccountForm: FC = ({ }) => {

    const handleSubmit = async (values: HSaccountFormFields) => {
        console.log(values);
        const { its_no, id, last_name, first_name, email, phone_no = "" } = values;
        if (!id) { return }
        try {
            const res = await AddHSuser({ id, first_name, last_name, its_no: Number(its_no), email, phone_no });
            handleClose()
            await SuccessDialog("Account Created", "New account successfully created.");
        } catch (error) {
            //@ts-ignore
            const message = error.errors[0].message;
            await ErrorDialog("Request Failed", message);
        }
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    return (
        <>
            <Button color='primary' onClick={handleClickOpen}  ><Grid container alignItems='center' ><Add /><span>Add Account</span></Grid></Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <HSaccountForm action="ADD" handleSubmit={handleSubmit} />
            </Dialog>
        </>
    )
}