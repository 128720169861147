import React, { FC, useState, useEffect, FormEventHandler } from 'react';
import Table from '../../Table';
import { Grid, Typography, Dialog, TextField, } from '@material-ui/core';
import Button from '../../Button';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { getQardanApplicantAndInstallments } from '../../../reduxStore/slices/sbqhPortalSlice';
import { ErrorDialog, SuccessDialog } from '../../Alert';
import { tableLoading } from '../HS_AccountsTab';
import moment from 'moment';
import { ComparisonOperators } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { formatNum, TransactionDescription } from '../../../helper';
import { Skeleton } from '@material-ui/lab';


const Qardan_ApplicantSearch: FC = ({ }) => {
    const { applicantInfo, installments: { data: installmentsList, totalCount }, input: { filterOptions, applicantId } } = useAppSelector(s => s.sbqhPortal.qardan_applicant_search);
    const isFetching = useAppSelector(s => s.sbqhPortal.fetchingStatus.getQardanApplicantAndInstallments);
    const getQardanApplicantAndInstallmentsError = useAppSelector(s => s.sbqhPortal.error.getQardanApplicantAndInstallments);
    const dispatch = useAppDispatch();

    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        dispatch(getQardanApplicantAndInstallments({ applicantId, installmentfilter: { ...filterOptions, pageNumber, pageSize } }));
    }

    const handleApplyFilter: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const applicantIdInput = e.currentTarget.elements.item(0) as HTMLInputElement;
        const applicantId = applicantIdInput?.value.toLocaleLowerCase();
        dispatch(getQardanApplicantAndInstallments({
            fetchApplicantDetails: true,
            applicantId,
            installmentfilter: { ...filterOptions, filterBy: [{ field: "applicant_id", operator: ComparisonOperators.Eq, value: applicantId }] }
        }));

    }

    useEffect(() => {
        if (getQardanApplicantAndInstallmentsError && !isFetching) {
            ErrorDialog("Search Error", getQardanApplicantAndInstallmentsError || "Some error occurred while searching.")
        }
    }, [getQardanApplicantAndInstallmentsError, isFetching])

    return (
        <Grid >
            <br />
            <Grid component='form' onSubmit={handleApplyFilter} container justify='space-between' alignItems='center' wrap='wrap' >
                <TextField style={{ margin: "5px" }} label="File No" type="text" />
                {/* <TextField style={{ margin: "5px" }} label="Date From" type="date" defaultValue={moment().subtract(1, 'months').format('YYYY-MM-DD')} /> */}
                {/* <TextField style={{ margin: "5px" }} label="Date To" type="date" defaultValue={moment().format('YYYY-MM-DD')} /> */}
                <Button style={{ margin: "5px" }} type='submit' color='primary' >Search</Button>
            </Grid>
            <br /><br /><br />
            <Typography variant='h5' >Applicant Details</Typography>
            <hr />
            <br />

            {(!applicantInfo && !isFetching) ?
                <>
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >No applicant details to display</Typography>
                    <br /><br /><br />
                </> :

                <>
                    <Grid>
                        {applicantInfo ?
                            <>
                                <Typography><b>File No:</b> {applicantInfo.id.toUpperCase()}</Typography>
                                <Typography><b>Title:</b> {applicantInfo.last_name}</Typography>
                                <Typography><b>First Name:</b> {applicantInfo.first_name}</Typography>
                                <Typography><b>Last Name:</b> {applicantInfo.last_name}</Typography>
                                {applicantInfo.company_name && <Typography ><b>Company Name:</b> {applicantInfo.company_name}</Typography>}
                                <Typography><b>ITS.No:</b> {applicantInfo.its_no}</Typography>
                                <Typography><b>Email:</b> <a href={`mailto:${applicantInfo.email}`}>{applicantInfo.email}</a></Typography>
                                <Typography><b>Phone:</b> <a href={`tel:${applicantInfo.phone_no}`}>{applicantInfo.phone_no}</a></Typography>

                                <Typography style={{ paddingTop: "7px", paddingBottom: "3px", fontSize: '18px' }} ><u><b>Qardan Allocation:</b></u></Typography>
                                <Typography><b>Payment Date:</b> {moment(applicantInfo.loanAllocation?.date).format("DD-MMM-YYYY")}</Typography>
                                <Typography><b>Repayment Start Date:</b> {moment(applicantInfo.loanAllocation?.repayment_date).format("DD-MMM-YYYY")}</Typography>
                                <Typography><b>Repayment Duration:</b> {applicantInfo.loanAllocation?.repayment_duration_in_months} Months</Typography>
                                <Typography><b>Repayment Status:</b> {applicantInfo.loanAllocation?.status.replace(/_/g, ' ')}</Typography>
                                <Typography><b>Qardan Received:</b> £{formatNum(applicantInfo.loanRepaid)}</Typography>
                                <Typography style={{ color: 'red' }} ><b>Qardan Outstanding:</b> £{formatNum(Math.abs((applicantInfo.loanAllocation?.amount || 0) - (applicantInfo.loanRepaid || 0)))}</Typography>
                                <Typography><b>Qardan Given:</b> £{formatNum(applicantInfo.loanAllocation?.amount)}</Typography>
                            </> :
                            <>
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <br />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                            </>
                        }
                    </Grid>

                    <br />

                    <Table
                        label={<Typography variant='h4' align='center' >Qardan Hasana Installments</Typography>}
                        columns={[
                            { id: "id", minWidth: 70, align: "left", label: "ID", },
                            { id: "applicant_id", minWidth: 130, align: "left", label: "File.No", format: (val: string) => val?.toUpperCase() },
                            { id: "date", minWidth: 130, align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                            { id: "amount", minWidth: 140, align: "left", label: "Amount", format: (val: number) => <p style={{ color: val < 0 ? "red" : 'green' }} >£{formatNum(Math.abs(val))}</p> },
                            { id: "description", minWidth: 150, align: "left", label: "Description", format: (val: string) => TransactionDescription[val] || val },
                        ]}
                        rows={installmentsList || []}
                        totalRows={totalCount}
                        pageNumber={filterOptions.pageNumber! - 1}
                        onChangePageNo={handleChangePage}
                        onChangeRowsPerPage={handleChangePage}
                        dataFetchingAsync={true}
                        loading={isFetching}
                        tableStyle={{ height: ((isFetching && !installmentsList?.length) || !installmentsList?.length) ? undefined : 400, width: '100%' }}
                        emptyRowMsg={isFetching ? tableLoading : <Grid  >
                            <br /><br /><br />
                            <Typography variant='h4' align='center' color='textSecondary' >There are no transactions to display</Typography>
                            <br /><br /><br />
                        </Grid>}
                    />

                </>
            }

        </Grid>
    );
};

export default Qardan_ApplicantSearch;
