import React, { FC, memo, useEffect, useState } from 'react';
import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ArrowBack, Delete, } from '@material-ui/icons';
import { SquareEditOutline } from 'mdi-material-ui';
import { GetAllHSusers, GetHSuserInfo } from '../../../graphql/queries';
// import { GetAllHSusersOutput } from '../../../graphql/types';
import { HSuser, HSuserBasicInfo, HSuserFullInfo, UserType } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import Button from '../../Button';
import HSaccountForm, { FormFields as HSaccountFormFields } from '../../Form/HSaccountForm';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { getAllHSusers, changeHSuserDetails } from '../../../reduxStore/slices/sbqhPortalSlice';
import { DeleteHSuser } from '../../../graphql/mutations';
import { ErrorDialog, SuccessDialog, ConfirmationDialog } from '../../Alert';
import { GraphQLResult } from '../../../typedefs';
import { formatNum } from '../../../helper';
import { changeHSuserPasswordBySBQHuser } from '../../../reduxStore/slices/sbqhPortalSlice/reducers';
import ResetPassword from '../../Form/ResetPassword';

interface AccountDetailDialogProps {
    userId: string;
    open: boolean;
    handleClose: () => void;
}

const AccountDetailDialog: FC<AccountDetailDialogProps> = ({ userId, open, handleClose }) => {
    const [data, setData] = useState<HSuserFullInfo | null>(null)
    const [isDeleting, setDeleting] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [dialogView, setDialogView] = useState<"EDIT" | "VIEW" | "RESET_PASSWORD">("VIEW");
    const dispatch = useAppDispatch();
    const { pageNumber, pageSize, filterBy } = useAppSelector(state => state.sbqhPortal.hs_users_data);

    const loadData = async () => {
        setFetching(true);
        try {
            const res = await GetHSuserInfo()(UserType.SbqhUser, { userId });
            if (!res.data) { return }
            setData(res.data.getHSuserInfo);
            // console.log(res.data.getHSuserInfo)
        } catch (err) { const error = err as GraphQLResult<any>; console.log(error.errors); }
        setFetching(false);
    }
    const handleDeleteAccount = async () => {
        setDeleting(true);
        try {
            const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, delete it!" })
            if (!isConfirmed) { setDeleting(false); return }
            const res = await DeleteHSuser(userId);
            console.log(res.data?.deleteHSuser.message)
            SuccessDialog("Account deleted", res.data?.deleteHSuser.message!);
            handleClose();
            dispatch(getAllHSusers({ pageNumber, pageSize, filterBy }))
        } catch (err) {
            const error = err as GraphQLResult<any>
            ErrorDialog("Account cannot be deleted", (error?.errors && error.errors[0]?.message) || "");
        }
        setDeleting(false);
    }
    /*use to set the dialog screen to Edit detail form*/
    const setOnEditDialogview = () => { setDialogView("EDIT") }
    /*use to set the dialog screen to View details*/
    const setOnViewDialogview = () => { setDialogView("VIEW") }
    /*use to set the dialog screen to Reset Password*/
    const setOnResetPasswordDialogview = () => { setDialogView("RESET_PASSWORD") }

    const _handleClose = () => { handleClose(); setOnViewDialogview() }

    useEffect(() => {
        !isFetching && data?.id !== userId && loadData()
    }, [userId])

    return (
        <Dialog open={open} onClose={_handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
            {dialogView === "VIEW" && <ViewDetails
                handleDeleteAccount={handleDeleteAccount}
                data={data}
                isDeleting={isDeleting}
                isFetchingData={isFetching}
                handleEdit={setOnEditDialogview}
                handleResetPassword={setOnResetPasswordDialogview}
            />}

            {dialogView === "EDIT" && <EditDetails
                data={data}
                handleBack={setOnViewDialogview}
                onChangeData={setData}
            />}

            {dialogView === "RESET_PASSWORD" && <UpdatePassword
                hsUserid={data?.id || ""}
                handleBack={setOnViewDialogview}
            />}

        </Dialog>
    )
}

export default memo(AccountDetailDialog);


interface ViewDetailsProps {
    handleDeleteAccount: () => void;
    data: HSuserFullInfo | null;
    isDeleting: boolean;
    isFetchingData: boolean;
    handleEdit: () => void;
    handleResetPassword: () => void;
}
const ViewDetails: FC<ViewDetailsProps> = ({ handleDeleteAccount, data, isDeleting, isFetchingData, handleEdit, handleResetPassword }) => {
    return (
        <>
            <DialogTitle style={{ paddingBottom: "7px" }}  >
                <Grid container justify='space-between' alignItems='center' style={{ color: 'inherit' }} >
                    <b>Account Details</b>
                    <IconButton title='delete account' disabled={isDeleting} onClick={handleDeleteAccount} ><Delete /></IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {(data && !isFetchingData) ?
                    <>
                        <Typography><b>Account ID:</b> {data.id.toUpperCase()}</Typography>
                        <Typography><b>First Name:</b> {data.first_name}</Typography>
                        <Typography><b>Last Name:</b> {data.last_name}</Typography>
                        <Typography><b>ITS.No:</b> {data.its_no || ""}</Typography>
                        <Typography><b>Email:</b> <a href={`mailto:${data.email}`}>{data.email}</a></Typography>
                        <Typography><b>Phone:</b> <a href={`tel:${data.phone_no}`}>{data.phone_no}</a></Typography>
                        <Typography><b>Account balance:</b> £{formatNum(data.balance_amount)}</Typography>
                    </> :
                    <>
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                    </>
                }
            </DialogContent>

            <DialogActions>
                {/* <Button onClick={handleClose} color="primary">
                    Save
                </Button> */}
                <Button color="primary" disabled={isDeleting} onClick={handleResetPassword}>
                    <Grid container alignItems='center' style={{ padding: '3px' }} >Reset Password</Grid>
                </Button>
                <Button color="primary" disabled={isDeleting} onClick={handleEdit}>
                    <Grid container alignItems='center' ><SquareEditOutline /><span>Edit</span></Grid>
                </Button>
            </DialogActions>
        </>
    )
}


interface EditDetailsProps {
    // handleUpdateAccount: () => void;
    data: HSuserFullInfo | null;
    onChangeData?: (val: HSuserFullInfo) => void
    handleBack: () => void;
}
const EditDetails: FC<EditDetailsProps> = ({ handleBack, data, onChangeData }) => {

    const dispatch = useAppDispatch();
    // const isChangingHSuserDetails = useAppSelector(s => s.sbqhPortal.fetchingStatus.changeHSuserDetails);
    const handleSubmit = async ({ first_name, last_name, its_no, email, phone_no }: HSaccountFormFields) => {
        await new Promise((resolve, reject) => {
            data && dispatch(changeHSuserDetails({
                id: data.id,
                first_name,
                last_name,
                email,
                phone_no,
                its_no: Number(its_no) || undefined,
                callback: (err, res) => { err && reject(err); res && resolve(res); }
            }))
        })
        data && onChangeData && onChangeData({ ...data, first_name, last_name, its_no: Number(its_no) || undefined, email, phone_no, });
        handleBack();
    }

    return (
        <>
            <DialogTitle style={{ paddingBottom: "0px" }}  >
                <IconButton onClick={handleBack} ><ArrowBack /></IconButton>
            </DialogTitle>

            <DialogContent>
                <HSaccountForm
                    action='UPDATE'
                    initialValues={{ first_name: data?.first_name, its_no: data?.its_no?.toString() || "", last_name: data?.last_name, email: data?.email, phone_no: data?.phone_no }}
                    handleSubmit={handleSubmit}
                />
            </DialogContent>
        </>
    )
}

const UpdatePassword = ({ handleBack, hsUserid }) => {
    const dispatch = useAppDispatch();
    const handleSubmit = async ({ password }) => {
        if (!hsUserid) return;
        await new Promise((resolve, reject) => {
            dispatch(changeHSuserPasswordBySBQHuser({
                hsUserid, newPassword: password,
                callback: (err, res) => { err && reject(err); res && resolve(res); }
            }))
        })
        handleBack();
    }

    return (<>
        <DialogTitle style={{ paddingBottom: "0px" }}  >
            <IconButton onClick={handleBack} ><ArrowBack /></IconButton>
        </DialogTitle>

        <DialogContent>
            <ResetPassword handleSubmit={handleSubmit} />
        </DialogContent>
    </>)
}