import React, { FC, useState, useEffect, FormEventHandler } from 'react';
import Table from '../../Table';
import { Grid, Typography, Dialog, TextField, } from '@material-ui/core';
import Button from '../../Button';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { getHSuserAccountAndTransaction } from '../../../reduxStore/slices/sbqhPortalSlice';
import { ErrorDialog, SuccessDialog } from '../../Alert';
import { tableLoading } from '../HS_AccountsTab';
import moment from 'moment';
import { ComparisonOperators } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { formatNum, TransactionDescription } from '../../../helper';
import { Skeleton } from '@material-ui/lab';


const BankReconciliation: FC = ({ }) => {
    const { userDetails, userTransactions: { data: transactionsList, totalCount }, input: { filterOptions, userId } } = useAppSelector(s => s.sbqhPortal.analytics_hs_bank_reconciliation);
    const isFetching = useAppSelector(s => s.sbqhPortal.fetchingStatus.getHSuserAccountAndTransaction);
    const getHSuserAccountAndTransactionError = useAppSelector(s => s.sbqhPortal.error.getHSuserAccountAndTransaction);
    const dispatch = useAppDispatch();


    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        dispatch(getHSuserAccountAndTransaction({ userId, transactionfilter: { ...filterOptions, pageNumber, pageSize } }));
    }

    const handleApplyFilter: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const accountIdInput = e.currentTarget.elements.item(0) as HTMLInputElement;
        // const dateFrom = e.currentTarget.elements.item(1) as HTMLInputElement;
        // const dateTo = e.currentTarget.elements.item(2) as HTMLInputElement;
        // console.log(dateFrom); console.log(dateTo);
        const userId = accountIdInput?.value.toLocaleLowerCase();
        dispatch(getHSuserAccountAndTransaction({
            fetchUserInfo: true,
            userId,
            transactionfilter: { ...filterOptions, filterBy: [{ field: "account_id", operator: ComparisonOperators.Eq, value: userId.slice(2) }] }
        }));

    }

    useEffect(() => {
        if (getHSuserAccountAndTransactionError && !isFetching) {
            ErrorDialog("Search Error", getHSuserAccountAndTransactionError || "Some error occurred while searching.")
        }
    }, [getHSuserAccountAndTransactionError, isFetching])

    return (
        <Grid container direction='column' >
            <br />
            <Grid component='form' onSubmit={handleApplyFilter} container justify='space-between' alignItems='center' wrap='wrap' >
                <TextField style={{ margin: "5px" }} label="Account ID" type="text" />
                {/* <TextField style={{ margin: "5px" }} label="Date From" type="date" defaultValue={moment().subtract(1, 'months').format('YYYY-MM-DD')} /> */}
                {/* <TextField style={{ margin: "5px" }} label="Date To" type="date" defaultValue={moment().format('YYYY-MM-DD')} /> */}
                <Button style={{ margin: "5px" }} type='submit' color='primary' >Search</Button>
            </Grid>
            <br /><br /><br />
            <Typography variant='h5' >Account Details</Typography>
            <hr />
            <br />

            {(!userDetails && !isFetching) ?
                <>
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >No account details to display</Typography>
                    <br /><br /><br />
                </> :

                <>
                    <Grid>
                        {userDetails ?
                            <>
                                <Typography><b>Account ID:</b> {userDetails.id.toUpperCase()}</Typography>
                                <Typography><b>First Name:</b> {userDetails.first_name}</Typography>
                                <Typography><b>Last Name:</b> {userDetails.last_name}</Typography>
                                <Typography><b>ITS.No:</b> {userDetails.its_no || ""}</Typography>
                                <Typography><b>Email:</b> <a href={`mailto:${userDetails.email}`}>{userDetails.email}</a></Typography>
                                <Typography><b>Phone:</b> <a href={`tel:${userDetails.phone_no}`}>{userDetails.phone_no}</a></Typography>
                                <Typography><b>Account balance:</b> £{formatNum(userDetails.balance_amount)}</Typography>
                            </> :
                            <>
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                                <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                            </>
                        }
                    </Grid>

                    <br />

                    <Table
                        label={<Typography variant='h4' align='center' >Account Transactions</Typography>}
                        columns={[
                            { id: "id", align: "left", label: "Transaction ID", },
                            // { id: "account_id", align: "left", label: "Acc ID", },
                            { id: "date", align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                            { id: "amount", align: "left", label: "Amount", format: (val: number) => <p style={{ color: val < 0 ? "red" : 'green' }} >£{formatNum(Math.abs(val))}</p> },
                            { id: "description", align: "left", label: "Description", format: (val: string) => TransactionDescription[val] || val },
                        ]}
                        rows={transactionsList || []}
                        totalRows={totalCount}
                        pageNumber={filterOptions.pageNumber! - 1}
                        onChangePageNo={handleChangePage}
                        onChangeRowsPerPage={handleChangePage}
                        dataFetchingAsync={true}
                        loading={isFetching}
                        tableStyle={{ height: ((isFetching && !transactionsList?.length) || !transactionsList?.length) ? undefined : 400, width: '100%' }}
                        emptyRowMsg={isFetching ? tableLoading : <Grid  >
                            <br /><br /><br />
                            <Typography variant='h4' align='center' color='textSecondary' >There are no transactions to display</Typography>
                            <br /><br /><br />
                        </Grid>}
                    />

                </>
            }

        </Grid>
    );
};

export default BankReconciliation;
