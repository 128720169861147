import React, { FC, useEffect, useState } from 'react';
import Table from '../Table';
import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { } from '@material-ui/icons';
import { GetWithdrawalRequestDetails } from '../../graphql/queries';
import { } from '../../graphql/mutations';
import { GetAllWithdrawalRequestsOutput } from '../../graphql/types';
import { PaymentWithdrawalRequest, WithdrawalRequestStatus, WithdrawalRequestWithHSuserInfo, Response } from '../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { Button, BackDrop } from '../';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from '../../reduxStore/store';
import { getAllWithdrawalRequests, fulfillPaymentWithdrawlRequests } from '../../reduxStore/slices/sbqhPortalSlice';
import { tableLoading } from './HS_AccountsTab';
import { ConfirmationDialog, ErrorDialog, SuccessDialog } from '../Alert';
import { formatNum } from '../../helper';

const HS_WithdrawalRequestTab: FC = ({ }) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [rowDetails, setRowDetails] = useState<WithdrawalRequestWithHSuserInfo | null>(null);
    const { data, pageNumber, pageSize, totalCount, status } = useSelector((state: RootStateType) => state.sbqhPortal.hs_withdrawalRequests_data)
    const isFetching = useSelector((state: RootStateType) => state.sbqhPortal.fetchingStatus.getAllWithdrawalRequests);
    const fulfillingWithdrawlRequests = useSelector((state: RootStateType) => state.sbqhPortal.fetchingStatus.fulfillPaymentWithdrawlRequests);
    const dispatch = useAppDispatch();
    const [selectedRows, setSelectedRows] = useState<WithdrawalRequestWithHSuserInfo['account_id'][]>([]);

    const loadData = async () => {
        dispatch(getAllWithdrawalRequests({ pageNumber, pageSize, status }));
    }
    const handleChangePage = (pageNumber: number, pageSize: number) => {
        dispatch(getAllWithdrawalRequests({ pageNumber: pageNumber + 1, pageSize, status }));
    }
    const onRowSelection = (_selectedRows: number[]) => {
        data && setSelectedRows(_selectedRows.map(i => data[i].account_id))
    }
    const handleConfirmRequest = async () => {
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "Do you want to confirm this withdrawal request?" })
        if (!isConfirmed) { return }
        dispatch(fulfillPaymentWithdrawlRequests({ account_ids: selectedRows }))
    }

    useEffect(() => {
        !isFetching && !data && loadData()
    }, [])

    return (
        <Grid >
            <br />

            <Grid container justify='flex-end' alignItems='center'>
                <Button disabled={!selectedRows.length} color='primary' onClick={handleConfirmRequest}>
                    Confirm Request
                </Button>
            </Grid>
            <br />

            <BackDrop open={fulfillingWithdrawlRequests} >
                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
            </BackDrop>

            {rowDetails &&
                <RequestConfirmationDialog open={openDialog} handleClose={() => { setOpenDialog(false) }} requestId={rowDetails.request_id} />}

            <Table
                label={<Typography variant='h4' align='center' >Funds Withdrawal Requests</Typography>}
                columns={[
                    { id: "request_id", minWidth: 40, align: "left", label: "ID", },
                    { id: "account_id", minWidth: 100, align: "left", label: "Acc ID", format: (val: string, rowValue) => <p style={{ cursor: "pointer", color: "purple", textDecoration: "underline" }} onClick={() => { setRowDetails(rowValue); setOpenDialog(true); }} >{val}</p> },
                    { id: "status", minWidth: 60, align: "left", label: "Status", format: (val: WithdrawalRequestStatus) => <span style={{ color: val === WithdrawalRequestStatus.Fulfilled ? 'green' : "orange" }} >{val}</span> },
                    { id: "requested_amount", minWidth: 60, align: "left", label: "Amount", format: (val: number) => `£${formatNum(val)}` },
                    { id: "first_name", minWidth: 200, align: "left", label: "First Name", },
                    { id: "last_name", minWidth: 200, align: "left", label: "Last Name", },
                    { id: "email", minWidth: 200, align: "left", label: "Email", },
                    { id: "request_date", minWidth: 200, align: "left", label: "Date", format: (val: string) => moment(val).format("DD MMM YY, HH:mm:ss") },
                ]}
                rows={data || []}
                totalRows={totalCount}
                pageNumber={pageNumber - 1}
                // pageSize={pageSize}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                checkboxSelection
                onRowSelection={onRowSelection}
                tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                // handleClickOnRow={(val: WithdrawalRequestWithHSuserInfo) => { setRowDetails(val); setOpenDialog(true); }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no request to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />

        </Grid>
    )
}

export default HS_WithdrawalRequestTab;



interface RequestConfirmationDialogProps {
    requestId: number;
    open: boolean;
    handleClose: () => void;
}
const RequestConfirmationDialog: FC<RequestConfirmationDialogProps> = ({ requestId, handleClose, open }) => {
    const [data, setData] = useState<PaymentWithdrawalRequest | null>(null)
    const [isFetching, setFetching] = useState(false);
    const [isRequestFulfilling, setRequestFulfilling] = useState(false);
    const { pageNumber, pageSize, status } = useSelector((state: RootStateType) => state.sbqhPortal.hs_withdrawalRequests_data)
    const dispatch = useAppDispatch();


    const loadData = async () => {
        setFetching(true);
        try {
            const res = await GetWithdrawalRequestDetails({ id: requestId });
            if (!res.data) { return }
            setData(res.data.getWithdrawalRequestDetails);
            // console.log(res.data.getWithdrawalRequestDetails)
        } catch (error) {
            //@ts-ignore
            error = error.errors;
            console.log(error);
        }
        setFetching(false);
    }

    const handleFulfillRequest = async () => {
        setRequestFulfilling(true);
        if (!data) { return }
        try {
            const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "Do you want to confirm this withdrawal request?" })
            if (!isConfirmed) { setRequestFulfilling(false); return }
            // const res = await FulfillPaymentWithdrawlRequests(data.account_id);
            const res = await new Promise<Response>((resolve, reject) => {
                dispatch(fulfillPaymentWithdrawlRequests({
                    account_ids: [data.account_id], callback: (err, res) => {
                        err && reject(err);
                        res && resolve(res);
                    }
                }))
            })
            // SuccessDialog("Request Not Confirmed", res.message);
            handleClose();
            // dispatch(getAllWithdrawalRequests({ pageNumber, pageSize, status }))

        } catch (error) {
            //@ts-ignore
            const message = error.errors[0].message;
            // ErrorDialog("Request Not Confirmed", message);
        }
        setRequestFulfilling(false);
    }

    useEffect(() => {
        !isFetching && data?.id !== requestId && loadData()
    }, [requestId])


    return (
        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
            <DialogTitle style={{ color: 'inherit' }} ><b>Request Details</b></DialogTitle>

            <DialogContent>
                {(data && !isFetching) ?
                    <>
                        <Typography>Request ID: <b>{data.id}</b></Typography>
                        <Typography>Requested amount: <b>£{data.amount}</b></Typography>
                        <Typography>Bank account number: <b>{data.bank_account_number}</b></Typography>
                        <Typography>Sort code: <b>{data.sort_code}</b></Typography>
                        <Typography>Name as per back account: <b>{data.name_as_per_bank_account}</b></Typography>
                        <Typography>Status: <b>{data.status}</b></Typography>
                    </> :
                    <>
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                    </>
                }
            </DialogContent>

            <DialogActions>
                <Button onClick={handleFulfillRequest} disabled={isRequestFulfilling} color="primary">
                    {isRequestFulfilling ? <div><CircularProgress color='inherit' /></div> : <div>Confirm</div>}
                </Button>
                <Button onClick={handleClose} disabled={isRequestFulfilling} color="primary" variant='outline'>
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    )
}