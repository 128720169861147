import React, { FC } from 'react';
import { Grid, Typography, } from '@material-ui/core';
import { useAppSelector } from '../../reduxStore/store';
import { ChangePasswordForm } from '../Form';
import { Props as ChangePasswordFormProps } from '../../components/Form/ChangePassword';
import { ChangeSQBHuserPassword } from '../../graphql/mutations';

const SBQH_ProfileTab: FC = ({ }) => {
    const name = useAppSelector(s => s.sbqhPortal.userDetails?.name);
    const email = useAppSelector(s => s.sbqhPortal.userDetails?.email);
    const access = useAppSelector(s => s.sbqhPortal.userDetails?.access);

    const handleChangePassword: ChangePasswordFormProps['handleSubmit'] = async (values) => {
        try {
            const res = await ChangeSQBHuserPassword(values.oldPassword, values.newConfirmPassword);
            return { successMsg: res.data?.changeSBQHuserPassword.message || "Password has been changed successfully" }
        } catch (error) {
            console.log("ChangePassword_Error", error);
            return { errorMsg: (error as any).errors[0].message }
        }
    }

    return (
        <Grid >
            <Grid container justify='flex-end' >
                <ChangePasswordForm handleSubmit={handleChangePassword} />
            </Grid>
            <Typography variant='h6' component='p' ><b>Name:</b> {name}</Typography>
            <Typography variant='h6' component='p' ><b>Email:</b> {email}</Typography>
            <Typography variant='h6' component='p' ><b>Access:</b></Typography>
            <Typography component='ul' style={{ marginLeft: "30px" }}  >
                {Object.entries(access || {}).map(([key, value], idx) => {
                    const portalName = key.split('_')[0];
                    const tabName = key.split('_').slice(1).join(' ');
                    return <Typography key={idx} component='li' >
                        {portalName} / {tabName} {!!value.read && "📒"} {!!value.write && "📝"}
                    </Typography>
                })}
            </Typography>
            {/* <Typography component='ul' style={{ marginLeft: "30px" }}  >
                <Typography component='li' >SBQH / Admin Users</Typography>
                <Typography component='li' >HS / Accounts</Typography>
                <Typography component='li' >HS / Transactions</Typography>
                <Typography component='li' >HS / Withdrawal Requests</Typography>
            </Typography> */}
        </Grid>
    )
}

export default SBQH_ProfileTab;
