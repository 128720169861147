import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { Grid, Box, CircularProgress, Typography, IconButton, TextField as MTextField } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import * as s from '../../Form/style';
import { Button } from '../../';
import { filterNumber } from '../../Form/UserDetails';
import { TransactionType } from '../../../typedefs';
// import { TransactionDescription } from '../../../helper';
import { qardanFileNumberRegex } from '../../../helper';
import MenuButton from '../../MenuButton';
// import moment from 'moment';
import { GetAllQardanApplicants } from '../../../graphql/queries';
import { QardanApplicant, ComparisonOperators, Order } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import MomentUtils from '@date-io/moment';

const formSchema = yup.object({
    // date: yup.date().matches(//g, { message: "Invalid format" }),
    applicant_id: yup.string().matches(qardanFileNumberRegex, { message: "Invalid format" }),
    amount: yup.string().required("Required"),
    description: yup.mixed<keyof typeof TransactionType>().required('Required'),
    dateTime: yup.date().required("Required"),
})

export interface FormFields extends yup.InferType<typeof formSchema> { }

const formInitialValues: FormFields = {
    dateTime: new Date(),
    amount: "",
    description: "" as any,
    applicant_id: ""
}

interface QardanInstallmentFormProps {
    initialValues?: Partial<FormFields>;
    action: "ADD" | "UPDATE";
    handleSubmit: (values: FormFields) => Promise<void>;
}
const QardanInstallmentForm: FC<QardanInstallmentFormProps> = ({ initialValues = {}, action, handleSubmit }) => {
    const [applicants, setapplicants] = useState<QardanApplicant[]>([]);

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        console.log(values.dateTime);

        await handleSubmit(values)
    }

    const searchHsUser = async (applicantId: string) => {
        const pageNumber = 1, pageSize = 4;
        const { data } = await GetAllQardanApplicants({
            filter: {
                filterBy: [{ field: 'id', operator: ComparisonOperators.Contains, value: applicantId }],
                pageNumber, pageSize, sortBy: { fields: ["id"], order: Order.Desc }
            }
        })
        setapplicants(data?.getAllQardanApplicants.list || [])
    }

    const handleValidation = (values: FormFields) => {
        const error: { [P in keyof FormFields]?: string; } = {};
        if (action === "ADD" && !values.applicant_id) {
            error.applicant_id = "Required";
        }
        if (Number(values.amount || 0) === 0) {
            error.amount = "Required";
        }
        return error;
    }

    return (
        <Formik initialValues={{ ...formInitialValues, ...initialValues }}
            validationSchema={formSchema}
            onSubmit={onSubmit}
            validate={handleValidation}
        >
            {({ isSubmitting, values, errors, handleChange, setFieldValue }) => {
                // console.log("values", values)
                return <MuiPickersUtilsProvider utils={MomentUtils} >
                    <Form style={action === "ADD" ? { ...s.formStyle(), height: "360px" } : undefined} >
                        {/* ///////////////////////////////////////// fields /////////////////////////////////////// */}
                        <s.LabelWrap>
                            <Typography variant='h5' align='center' >{action === "ADD" ? "Add New Transaction" : "Update Transaction Details"}</Typography>
                        </s.LabelWrap>
                        <br />
                        {values.applicant_id?.toLowerCase() === applicants[0]?.id?.toLowerCase() &&
                            <Typography variant='h6'  ><b>Applicant: </b>{applicants[0]?.first_name} {applicants[0]?.last_name}</Typography>
                        }
                        <br />
                        <Grid container item spacing={1} xs={12} justify='center' alignItems='flex-start' >
                            {action === "ADD" && <Grid item container alignItems='center' >
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={applicants}
                                    getOptionLabel={(v) => v ? v.id : ""}
                                    style={{ width: "100%" }}
                                    vocab={values.applicant_id}
                                    freeSolo
                                    renderOption={(v, { inputValue }) => {
                                        return <Box>
                                            <Typography variant='body2' >{v.first_name} {v.last_name}</Typography>
                                            <Typography variant='caption' >{v.id.toUpperCase()}</Typography>
                                        </Box>
                                    }}
                                    onChange={(e, val) => setFieldValue("applicant_id", (val as any)?.id || "")}
                                    onInputChange={(e, newInputValue) => { searchHsUser(newInputValue) }}
                                    renderInput={(params) => <MTextField {...params} value={values.applicant_id} required onChange={e => setFieldValue("applicant_id", e.target.value)} fullWidth label="Account ID" type='text' variant='outlined' />}
                                />
                                {errors.applicant_id && <Typography variant='caption' style={{ color: 'red' }} >{errors.applicant_id}</Typography>}
                            </Grid>
                            }
                            <br />
                            <Grid item container alignItems='center' >
                                <Field required fullWidth component={TextField} placeholder="0.00" name='amount' label="Amount" type='number' variant='outlined' />
                            </Grid>
                            <br />
                            <Grid xs={12} sm={6} item container alignItems='center' justify='flex-end'>
                                <Field required fullWidth component={TextField} name='description' label="Description" type='text' variant='outlined' />
                                <DescriptionSelect onSelect={(value) => { setFieldValue('description', value) }} />
                            </Grid>
                            <br />
                            <Grid xs={12} sm={6} item container alignItems='center' >
                                <Field fullWidth required component={KeyboardDatePicker} name='dateTime' label="Date" format="DD/MM/yyyy" variant='outlined' InputLabelProps={{ shrink: true, }} KeyboardButtonProps={{ 'aria-label': 'change date' }} />
                            </Grid>
                            <br />
                            <Grid item container justify='center' >
                                <Button
                                    variant='contained' size='medium'
                                    disabled={isSubmitting} color='primary' type="submit" >
                                    {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>{action === "ADD" ? "Add" : "Update"}</div>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </MuiPickersUtilsProvider>
            }}
        </Formik>
    )
}

export default QardanInstallmentForm;

const DescriptionSelect: FC<{ onSelect?: (value: string) => void }> = ({ onSelect }) => {
    return (
        <div style={{ position: "absolute" }} >
            <MenuButton
                trigButton={(handleClick) => <IconButton onClick={handleClick} size='small' ><ArrowDropDown /></IconButton>}
                menuItems={Object.entries(TransactionType).map(([key, value],) => ({
                    item: value,
                    id: value
                }))}
                onItemClick={(itemId) => { onSelect && onSelect(itemId) }}
            />
        </div>
    )
}

