import React, { FC, useState } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import * as s from './style';
import { Grid, CircularProgress, Typography, InputAdornment, IconButton } from '@material-ui/core';
import BackDrop from '../BackDrop';
// import { MenuItem, Typography, Grid, TextField as MiTextField, InputAdornment, , Hidden } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Button } from '../';
import * as yup from 'yup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReCAPTCHA from "react-google-recaptcha";


export interface FormFields {
    password: string
}

export interface Props {
    handleSubmit: (values: FormFields) => Promise<void>
}

const ResetPassword: FC<Props> = ({ handleSubmit }) => {
    const [showPassword, setShowPassword] = useState(false);
    const formSchema = yup.object({
        password: yup.string().required('Required')
    })

    const formInitialValues = { password: "" }

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        await handleSubmit(values);
        setSubmitting(false);
    }

    return (
        <Formik initialValues={formInitialValues}
            validationSchema={formSchema}
            onSubmit={onSubmit}
        >
            {({ isValid, isSubmitting }) => (
                <>
                    <Form style={{ ...s.formStyle(), minWidth: '280px', maxWidth: '500px', margin: '0 auto' }} >
                        <s.LabelWrap>
                            <Typography variant='h5' align='center' >Reset Password</Typography>
                        </s.LabelWrap>

                        <br />

                        <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                            <Grid item container alignItems='center' >
                                <Field
                                    required component={TextField} fullWidth name='password' label='Password' variant='outlined'
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: <InputAdornment position='end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { setShowPassword(!showPassword) }}
                                                onMouseDown={() => { setShowPassword(!showPassword) }}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <br />
                            <Grid item container direction='column' >
                                <Button disabled={(!isValid || isSubmitting)} color='primary' size='large' type='submit' >
                                    {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Reset</div>}
                                </Button>
                            </Grid>
                        </Grid>

                    </Form>
                </>
            )}
        </Formik>
    )
}

export default ResetPassword;
