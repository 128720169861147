import React, { FC, useEffect, useState } from 'react';
import { Grid, Typography, Checkbox, Menu, MenuItem, IconButton } from '@material-ui/core';
import Table from '../../Table';
import { ConfirmationDialog } from '../../Alert';
import { getAllSBQHusers, changeSBQHusersAccess, deleteSBQHuser } from '../../../reduxStore/slices/sbqhPortalSlice';
import { useAppSelector, useAppDispatch } from '../../../reduxStore/store';
import { SbqHuser } from '../../../graphql/types';
import { Access, SbqHuserAccess } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes'
import { Button } from '../..';
import lodashIsEqual from 'lodash.isequal';
import { ErrorDialog, SuccessDialog } from '../../Alert';
import { tableLoading } from '../HS_AccountsTab';
import AddSbqhUserForm from './AddSbqhUserForm';
import { Delete } from '@material-ui/icons';


const accessKeys: { [P in keyof Omit<SbqHuserAccess, "__typename">]: P } = {
    HS_Accounts: "HS_Accounts",
    HS_Transactions: "HS_Transactions",
    HS_Withdrawal_Requests: "HS_Withdrawal_Requests",
    SBQH_Admin_Users: "SBQH_Admin_Users",
    Qardan_Applicants: "Qardan_Applicants",
    Qardan_Installments: "Qardan_Installments"
}

const SBQH_AdminUsersTab: FC = ({ }) => {
    const { data, pageNumber, pageSize, totalCount, filterBy } = useAppSelector((state) => state.sbqhPortal.sbqh_users_data);
    const isFetching = useAppSelector((state) => state.sbqhPortal.fetchingStatus.getAllSBQHusers);
    const loadingChangeSBQHusersAccess = useAppSelector((state) => state.sbqhPortal.fetchingStatus.changeSBQHusersAccess);
    const isDeletingSBQHuser = useAppSelector((state) => state.sbqhPortal.fetchingStatus.deleteSBQHuser);
    const [changedUsersData, setChangedUsersData] = useState<SbqHuser[]>([]);
    const [tablecontextMenuState, setTablecontextMenuState] = useState<{ mouseX: null | number; mouseY: null | number; }>({ mouseX: null, mouseY: null });
    const dispatch = useAppDispatch();

    const handleClickTableContext = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, rowValues: SbqHuser, tableRowIndex: number) => {
        event.preventDefault();
        setTablecontextMenuState({ mouseX: event.clientX - 2, mouseY: event.clientY - 4, });
        console.log(rowValues, tableRowIndex)
    };

    const handleCloseTableContext = () => {
        setTablecontextMenuState({ mouseX: null, mouseY: null });
    };

    const handleDeleteSBQHuser = (userId: number) => async () => {
        // console.log(userId);
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, delete it!" })
        if (!isConfirmed) { return }
        dispatch(deleteSBQHuser({ id: userId }))
        handleCloseTableContext()
    }

    const handlechangeUserAccess = (index: number, accessName: keyof typeof accessKeys,) => (access: Access) => {
        setChangedUsersData(data => {
            let clone = JSON.parse(JSON.stringify(data));
            clone[index].access[accessName] = access;
            return clone;
        });
    }
    const handleChangePage = (pageNumber: number, pageSize: number) => {
        dispatch(getAllSBQHusers({ pageNumber: pageNumber + 1, pageSize, filterBy }));
    }
    const handleUpdateUsersAccess = async () => {
        const { error, response } = await new Promise<{ error: any, response: any }>((resolve, reject) => {
            dispatch(changeSBQHusersAccess({
                users_access: changedUsersData,
                callback: (err, res) => { resolve({ error: err, response: res }) }
            }))
        })
        if (response) {
            setChangedUsersData(data || []);
            await SuccessDialog("Access Updated", "Admin user access successfully updated");
        } else {
            await ErrorDialog("Request Failed", error.errors[0].message);
        }
    }

    useEffect(() => {
        if (!isFetching && !data) {
            dispatch(getAllSBQHusers({ pageNumber, pageSize, filterBy }));
            setChangedUsersData(data || []);
        }
    }, [])

    useEffect(() => {
        setChangedUsersData(data || []);
    }, [data])

    return (
        <>
            <Grid >
                <br />
                <Grid container justify='space-between' >
                    <AddSbqhUserForm />
                    <Button color='primary' disabled={loadingChangeSBQHusersAccess || lodashIsEqual(data, changedUsersData)} onClick={handleUpdateUsersAccess}  >
                        Update User Access
                    </Button>
                </Grid>
                <br />

                <Table
                    label={<Typography variant='h4' align='center' >Admin Users</Typography>}
                    columns={[
                        // { id: "id", align: "left", minWidth: 60, label: "ID", },
                        { id: "name", align: "left", minWidth: 200, label: "Name", },
                        { id: "email", align: "left", label: "Email", },
                        { id: "access", align: "left", minWidth: 220, label: "SBQH / Admin Users", format: (access: SbqHuserAccess, rowValues, tableRowIndex) => <ReadWrite {...access[accessKeys.SBQH_Admin_Users]} onChange={handlechangeUserAccess(tableRowIndex, "SBQH_Admin_Users")} /> },
                        { id: "access", align: "left", minWidth: 200, label: "HS / Accounts", format: (access: SbqHuserAccess, rowValues, tableRowIndex) => <ReadWrite {...access[accessKeys.HS_Accounts]} onChange={handlechangeUserAccess(tableRowIndex, "HS_Accounts")} /> },
                        { id: "access", align: "left", minWidth: 200, label: "HS / Transactions", format: (access: SbqHuserAccess, rowValues, tableRowIndex) => <ReadWrite {...access[accessKeys.HS_Transactions]} onChange={handlechangeUserAccess(tableRowIndex, "HS_Transactions")} /> },
                        { id: "access", align: "left", minWidth: 260, label: "HS / Withdrawal Requests", format: (access: SbqHuserAccess, rowValues, tableRowIndex) => <ReadWrite {...access[accessKeys.HS_Withdrawal_Requests]} onChange={handlechangeUserAccess(tableRowIndex, "HS_Withdrawal_Requests")} /> },
                        { id: "access", align: "left", minWidth: 220, label: "QH / Applicants", format: (access: SbqHuserAccess, rowValues, tableRowIndex) => <ReadWrite {...access[accessKeys.Qardan_Applicants]} onChange={handlechangeUserAccess(tableRowIndex, "Qardan_Applicants")} /> },
                        { id: "access", align: "left", minWidth: 220, label: "QH / Installments", format: (access: SbqHuserAccess, rowValues, tableRowIndex) => <ReadWrite {...access[accessKeys.Qardan_Installments]} onChange={handlechangeUserAccess(tableRowIndex, "Qardan_Installments")} /> },
                        {
                            id: "id", align: "left", minWidth: 200, label: "Action", format: (_, { id, name }) => <>
                                <IconButton onClick={handleDeleteSBQHuser(id)} title={`Delete ${name} account`} ><Delete /></IconButton>
                            </>
                        },
                    ]}
                    // width='100%'
                    // tableStyle={{ height: 400, minWidth:"100px", maxWidth:'760px',}}
                    // tableStyle={{ height: 400, }}
                    // pageSize={pageSize}
                    // handleClickOnRow={(val: WithdrawalRequestWithHSuserInfo) => { setRowDetails(val); setOpenDialog(true); }}
                    rows={changedUsersData || []}
                    totalRows={totalCount}
                    tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                    pageNumber={pageNumber - 1}
                    onChangePageNo={handleChangePage}
                    onChangeRowsPerPage={handleChangePage}
                    onContextMenu={handleClickTableContext}
                    dataFetchingAsync={true}
                    loading={isDeletingSBQHuser || isFetching}
                    emptyRowMsg={isFetching ? tableLoading : <Grid  >
                        <br /><br /><br />
                        <Typography variant='h4' align='center' color='textSecondary' >There are no admin users available</Typography>
                        <br /><br /><br />
                    </Grid>}
                />

            </Grid>
        </>
    )
}

export default SBQH_AdminUsersTab;


const ReadWrite: FC<{ onChange?: (access: Access) => void } & Omit<Access, "__typename">> = ({ read, write, onChange }) => {
    return (
        <Grid container>
            <Typography>
                Read:<Checkbox color='primary' size='small' checked={!!read} onChange={(_, read) => { onChange && onChange({ read: +read, write }) }} inputProps={{ 'aria-label': 'primary checkbox' }} />
            </Typography>
            <Typography>
                Write:<Checkbox color='primary' size='small' checked={!!write} onChange={(_, write) => { onChange && onChange({ read, write: +write }) }} inputProps={{ 'aria-label': 'primary checkbox' }} />
            </Typography>
        </Grid>
    )
}
