import React, { FC, useState, useEffect, FormEventHandler } from 'react';
import Table from '../../Table';
import { Grid, Typography, Dialog, TextField, } from '@material-ui/core';
import Button from '../../Button';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { getAllHSaccountsWithBalances } from '../../../reduxStore/slices/sbqhPortalSlice';
// import { ErrorDialog, SuccessDialog } from '../../Alert';
import { tableLoading } from '../HS_AccountsTab';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { formatNum } from '../../../helper';

const HSaccountsWithBalances: FC = () => {
    const { data, totalCount, filterOptions } = useAppSelector((state) => state.sbqhPortal.analytics_hs_accounts);
    const { pageNumber, pageSize, withTotalCount } = filterOptions;
    const isFetching = useAppSelector((state) => state.sbqhPortal.fetchingStatus.getAllHSaccountsWithBalances)
    const dispatch = useAppDispatch();
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months'));
    const [dateTo, setDateTo] = useState(moment());

    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        dispatch(getAllHSaccountsWithBalances({ ...filterOptions, pageNumber, pageSize, withTotalCount: false }));
    }
    const handleApplyFilter: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const _dateTo = dateTo.toDate().getTime();
        const _dateFrom = dateFrom.toDate().getTime();
        // console.log(dateFrom); console.log(dateTo);
        dispatch(getAllHSaccountsWithBalances({
            ...filterOptions, withTotalCount: true,
            dateTo: _dateTo, dateFrom: _dateFrom,
        }));

    }
    // useEffect(() => {
    //     !isFetching && !data?.length && loadData()
    // }, [])

    return (
        <Grid container direction='column' >
            <br />
            <Grid component='form' onSubmit={handleApplyFilter} container justify='space-around' alignItems='center' wrap='wrap' >
                {/* <TextField style={{ margin: "5px" }} label="Date From" type="date" defaultValue={moment().subtract(1, 'months').format('YYYY-MM-DD')} /> */}
                {/* <TextField style={{ margin: "5px" }} label="Date To" type="date" defaultValue={moment().format('YYYY-MM-DD')} /> */}
                <MuiPickersUtilsProvider utils={MomentUtils} >
                    <KeyboardDatePicker style={{ margin: "15px" }} format="DD/MM/yyyy" value={dateFrom} onChange={date => setDateFrom(date!)} label="Date From" />
                    <KeyboardDatePicker style={{ margin: "15px" }} format="DD/MM/yyyy" value={dateTo} onChange={date => setDateTo(date!)} label="Date To" />
                </MuiPickersUtilsProvider>
                <Button style={{ margin: "5px" }} type='submit' color='primary' >Search</Button>
            </Grid>
            <br />


            <Table
                label={<Typography variant='h4' align='center' >Accounts With Balances</Typography>}
                columns={[
                    { id: "id", align: "left", label: "Acc ID", format: (val: string) => val.toLocaleUpperCase() },
                    { id: "first_name", align: "left", label: "First Name", },
                    { id: "last_name", align: "left", label: "Last Name", },
                    { id: "its_no", align: "left", label: "ITS.No", },
                    { id: "balance_amount", align: "left", label: "Total Balance", format: (val: number) => <p style={{ color: val <= 0 ? "red" : 'green' }} >£{formatNum(Math.abs(val))}</p> },
                    // { id: "last_transaction_date", align: "left", label: "last_transaction_date", },
                    // { id: "date", align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                    // { id: "amount", align: "left", label: "Amount", format: (val: number) => `£${val.toFixed(2)}` },
                    // { id: "amount", align: "left", label: "Amount", format: (val: number) => <p style={{ color: val < 0 ? "red" : 'green' }} >£{(Math.abs(val)).toFixed(2)}</p> },
                    // { id: "description", align: "left", label: "Description", format: (val: string) => TransactionDescription[val] || val },
                ]}
                rows={data || []}
                totalRows={totalCount}
                pageNumber={pageNumber! - 1}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no accounts to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />

        </Grid>
    );
};

export default HSaccountsWithBalances;
