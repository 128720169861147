import React, { FC, useState } from 'react';
import { Grid, CircularProgress, Typography, InputAdornment, IconButton, Dialog } from '@material-ui/core';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { addSBQHuser } from '../../../reduxStore/slices/sbqhPortalSlice';
import { useAppDispatch } from '../../../reduxStore/store';
import * as s from '../../Form/style';
import { TextField } from 'formik-material-ui';
import * as yup from 'yup';
// import { Button } from '../';
import { BackDrop, Button } from '../../';
import { Add, Visibility, VisibilityOff } from '@material-ui/icons';
import { ErrorDialog, SuccessDialog } from '../../Alert';

const formSchema = yup.object({
    name: yup.string().required('Required'),
    email: yup.string().email("Incorrect email format").required('Required'),
})

export interface FormFields extends yup.InferType<typeof formSchema> { }

export interface Props {
    open?: boolean;
    // handleSubmit: (values: FormFields) => Promise<{ successMsg?: string, errorMsg?: string }>
}

const formInitialValues: FormFields = {
    name: "",
    email: "",
}

const AddSbqhUserForm: FC<Props> = ({ open = false }) => {
    const [_open, setOpen] = useState(open);

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };
    const dispatch = useAppDispatch();

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        const { email, name } = values;
        const { res, error } = await new Promise<{ error?: any, res?: any }>((resolve, reject) => {
            dispatch(addSBQHuser({
                email, name, callback: (err, res) => {
                    err && resolve({ error: err });
                    res && resolve({ res });
                }
            }))
        });
        if (res) {
            handleClose()
        }
        setSubmitting(false);
    }

    return (
        <>
            {!open &&
                <Button onClick={handleClickOpen} color='primary'>
                    <Grid container alignItems='center' ><Add /><span>Add User</span></Grid>
                </Button>
            }
            <Dialog open={_open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <Formik initialValues={formInitialValues}
                    validationSchema={formSchema}
                    onSubmit={onSubmit}
                // validate={handleValidation}
                >
                    {({ isValid, isSubmitting }) => {
                        return <Form style={{ ...s.formStyle(), }} >
                            <BackDrop open={isSubmitting} >
                                <Typography style={{ color: 'white' }} variant='h2' >Loading....</Typography>
                            </BackDrop>
                            {/* /////////////////////////////////////////   login credential fields /////////////////////////////////////// */}
                            <Grid container item spacing={1} xs={12} direction='column' justify='center' >
                                <s.LabelWrap style={{ padding: "10px" }} >
                                    <Typography variant='h5' align='center' >Add New User</Typography>
                                </s.LabelWrap>
                                <br />
                                <Grid item container alignItems='center' >
                                    <Field fullWidth component={TextField} name='name' label="Name" type='text' variant='outlined' />
                                </Grid>
                                <br />
                                <Grid item container alignItems='center' >
                                    <Field fullWidth component={TextField} name='email' label="Email" type='text' variant='outlined' />
                                </Grid>
                                <br />

                                <Grid item container direction='column' >
                                    <Button disabled={(!isValid || isSubmitting)} size='large' color='primary' type='submit' >
                                        {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>Add</div>}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    }}
                </Formik>
            </Dialog>
        </>
    )
}

export default AddSbqhUserForm;
