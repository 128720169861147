import React, { FC, useState, useEffect, FormEventHandler } from 'react';
import Table from '../../Table';
import { Grid, Typography, Dialog, TextField, } from '@material-ui/core';
import Button from '../../Button';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { getQardanApplicantsWithOutstandingBalances } from '../../../reduxStore/slices/sbqhPortalSlice';
import { ErrorDialog, SuccessDialog } from '../../Alert';
import { tableLoading } from '../HS_AccountsTab';
import moment from 'moment';
import { ComparisonOperators } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { formatNum, TransactionDescription } from '../../../helper';
import { Skeleton } from '@material-ui/lab';


const Qardan_ApplicantsWithOutstandingBalances: FC = ({ }) => {
    const { data: qardanApplicants, totalCount, filterOptions } = useAppSelector(s => s.sbqhPortal.qardan_applicants_with_outstanding_balances);
    const isFetching = useAppSelector(s => s.sbqhPortal.fetchingStatus.getQardanApplicantsWithOutstandingBalances);
    const error = useAppSelector(s => s.sbqhPortal.error.getQardanApplicantsWithOutstandingBalances);
    const dispatch = useAppDispatch();

    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        dispatch(getQardanApplicantsWithOutstandingBalances({ filter: { ...filterOptions, pageNumber, pageSize } }));
    }

    const loadData = async () => {
        dispatch(getQardanApplicantsWithOutstandingBalances({ filter: filterOptions }))
    }


    useEffect(() => {
        if (error && !isFetching) {
            ErrorDialog("Fetching Error", error || "Some error occurred while fetching.")
        }
    }, [error, isFetching])

    useEffect(() => {
        !isFetching && !qardanApplicants && loadData()
    }, [])


    return (
        <Grid >
            <br />
            <Table
                label={<Typography variant='h5' align='center' >Applicants With Outstanding Balances</Typography>}
                columns={[
                    { minWidth: 100, id: "id", align: "left", label: "File No", format: (val: string) => val?.toUpperCase() },
                    { minWidth: 100, id: "title", align: "left", label: "Title", },
                    { minWidth: 200, id: "first_name", align: "left", label: "First Name", },
                    { minWidth: 200, id: "last_name", align: "left", label: "Last Name", },
                    { minWidth: 180, id: "loanRepaid", align: "left", label: "£ Received", format: (v) => <p style={{ color: 'green' }} >£{formatNum(v)}</p> },
                    { minWidth: 180, id: "loanAllocation", align: "left", label: "£ Outstanding", format: (v, { loanAllocation, loanRepaid },) => <p style={{ color: 'red' }} >£{formatNum(Math.abs((loanAllocation?.amount || 0) - (loanRepaid || 0)))}</p> },
                    { minWidth: 180, id: "loanAllocation", align: "left", label: "£ Given", format: (v) => `£${formatNum(v.amount)}` },
                ]}
                rows={qardanApplicants || []}
                totalRows={totalCount}
                pageNumber={filterOptions.pageNumber - 1}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                tableStyle={{ height: ((isFetching && !qardanApplicants?.length) || !qardanApplicants?.length) ? undefined : 400, width: '100%' }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no applicants to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />

        </Grid>
    );
};

export default Qardan_ApplicantsWithOutstandingBalances;
