import React, { FC, memo, useEffect, useState } from 'react';
import { ListItemText, Collapse, List, ListItem, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import colors from '../../theme/colors';

export interface LeftPaneProps {
    items: ListItemType[],
    onListItemClick?: ExpandableListItemProps['onListItemClick']
    defaultselectedItemId?: ListNestedItemType['id']
}

////////////////////////////////  Component  ///////////////////////////////////
const LeftPane: FC<LeftPaneProps> = ({ onListItemClick, defaultselectedItemId, items }) => {
    const classes = useStyles();

    return (
        <div className={classes.container} >
            <List >
                {
                    items.map((item, idx) => {
                        return <ExpandableListItem
                            key={idx}
                            listTitle={item.listTitle}
                            listItems={item.listItems}
                            onListItemClick={onListItemClick}
                            selectedItemId={defaultselectedItemId}
                        />
                    })
                }
            </List>
        </div >
    )

}

export default memo(LeftPane);

type ListNestedItemType = { title: string, id: number }
export interface ListItemType {
    listTitle: string,
    listItems: ListNestedItemType[],
}

export interface ExpandableListItemProps extends ListItemType {
    onListItemClick?: (selectedItemId: ListNestedItemType['id']) => void
    selectedItemId?: ListNestedItemType['id']

}


export const ExpandableListItem: FC<ExpandableListItemProps> = ({ listItems, listTitle, onListItemClick, selectedItemId }) => {
    const [open, setOpen] = useState<boolean>(true);
    const classes = useStyles();
    const [currentSelectedItemId, setCurrentSelectedItemId] = useState<ListNestedItemType['id']>(-1);

    const handleClick = (selectedItemId: ListNestedItemType['id']) => () => {
        // setCurrentSelectedItem(listNestedItemTitle);
        onListItemClick && onListItemClick(selectedItemId)
    }

    useEffect(() => {
        if (
            selectedItemId &&
            listItems &&
            listItems.findIndex(item => item.id === selectedItemId) !== -1
        ) {
            // setOpen(true);
            setCurrentSelectedItemId(selectedItemId)
        }
        else {
            // setOpen(false);
            setCurrentSelectedItemId(-1);
        }

    }, [selectedItemId])

    return (
        <>
            <ListItem button onClick={() => { setOpen(!open) }}  >
                <ListItemText primary={listTitle} classes={{ primary: classes.listItemText }} />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapse} >
                <List component="div" disablePadding >
                    {listItems?.map(({ title, id }, idx) => {
                        return <ListItem button className={classes.nested} key={idx} onClick={handleClick(id)} component='div'>
                            <ListItemText
                                primary={title}
                                classes={{ primary: currentSelectedItemId === id ? classes.selectedItem : undefined }}
                            />
                        </ListItem>
                    })}
                </List>
            </Collapse>
        </>
    )
}


const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        width: '285px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        borderRight: '2px solid lightgrey',
        backgroundColor: "white"
    },
    listItemText: { fontSize: '18px', fontWeight: 'bolder' },
    collapse: {
        backgroundColor: "whitesmoke",
        "& $nested": { padding: '0', paddingLeft: theme.spacing(4), paddingRight: theme.spacing(2) }
    },
    nested: {}, // this should not be removed
    selectedItem: { color: colors[4], fontWeight: "bold" }
}));