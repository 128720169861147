import React, { FC } from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { Grid, CircularProgress, Typography, MenuItem, InputAdornment } from '@material-ui/core';
import * as yup from 'yup';
import { TextField } from 'formik-material-ui';
import * as s from './style';
import { Button } from '../';
import { filterNumber, schemaObject } from './UserDetails';
import { qardanFileNumberRegex } from '../../helper';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const formSchema = yup.object({
    id: yup.string().matches(qardanFileNumberRegex, { message: "Invalid format" }),
    title: yup.string().required("Required"),
    first_name: yup.string().required("Required"),
    last_name: yup.string().required('Required'),
    company_name: yup.string().required('Required'),
    its_no: schemaObject.itsNo.required('Required'),
    email: yup.string().email("Incorrect email format").required('Required'),
    phone_no: yup.string().required('Required'),
    loanAllocation: yup.object({
        date: yup.mixed<moment.Moment>().required("Required"),
        repayment_date: yup.mixed<moment.Moment>().required("Required"),
        amount: yup.string().min(1).required("Required"),
        repayment_duration_in_months: yup.string().required("Required"),
    })

})

export interface FormFields extends yup.InferType<typeof formSchema> { }

const formInitialValues: FormFields = {
    id: "",
    title: "",
    first_name: "",
    last_name: "",
    company_name: "",
    its_no: "",
    email: "",
    phone_no: "",
    loanAllocation: {
        amount: '0', date: moment(), repayment_date: moment(), repayment_duration_in_months: ""
    }
}

interface QardanApplicantFormProps {
    initialValues?: Partial<FormFields>;
    action: "ADD" | "UPDATE";
    handleSubmit: (values: FormFields) => Promise<void>;
    // hideFields?: (keyof FormFields)[]
}
const QardanApplicantForm: FC<QardanApplicantFormProps> = ({ initialValues = {}, action, handleSubmit }) => {

    const onSubmit = async (values: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
        console.log(values);
        await handleSubmit(values)
    }

    const handleValidation = (values: FormFields) => {
        const error: { [P in keyof FormFields]?: string; } = {};
        if (action === "ADD" && !values.id) {
            error.id = "Required";
        }
        if (values.phone_no) {
            const isPhoneNumValid = parsePhoneNumberFromString(values.phone_no, "GB")?.isValid();
            if (!isPhoneNumValid) {
                error.phone_no = 'Invalid mobile number'
                if (!values.phone_no.includes("+")) {
                    error.phone_no = 'Invalid mobile number, please also add country code'
                }
            }
        }
        // if (action === "ADD" && !values.its_no) {
        //     error.its_no = "Required";
        // }
        // if (action === "ADD" && !values.email) {
        //     error.email = "Required";
        // }
        // if (action === "ADD" && !values.phone_no) {
        //     error.phone_no = "Required";
        // }
        return error;
    }

    return (
        <Formik initialValues={{ ...formInitialValues, ...initialValues }}
            validationSchema={formSchema}
            onSubmit={onSubmit}
            validate={handleValidation}
        >
            {({ isSubmitting, handleChange }) => (
                <MuiPickersUtilsProvider utils={MomentUtils} >
                    <Form style={action === "ADD" ? { ...s.formStyle(), } : undefined} >
                        {/* ///////////////////////////////////////// fields /////////////////////////////////////// */}
                        <s.LabelWrap>
                            <Typography variant='h5' align='center' >{action === "ADD" ? "Add New Applicant" : "Update Applicant Details"}</Typography>
                        </s.LabelWrap>
                        <br />
                        <Grid container item spacing={1} xs={12} direction='column' justify='center' >

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                {action === "ADD" &&
                                    <Grid item xs={12} sm={6} >
                                        <Field fullWidth component={TextField} name='id' label="File.No" type='text' variant='outlined' />
                                    </Grid>}
                                <Grid xs={12} sm={6} item ><Field required component={TextField} select type='select' name='title' label='Title' variant="outlined" fullWidth children={
                                    ["Bhai", "Behen", "Shaikh", "Mulla"].map((title, idx) => (
                                        <MenuItem key={idx} value={title} >{title}</MenuItem>
                                    ))
                                } /></Grid>
                            </Grid>

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                <Grid item xs={12} sm={6} >
                                    <Field fullWidth component={TextField} name='first_name' label="First Name" type='text' variant='outlined' />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field fullWidth component={TextField} name='last_name' label="Last Name" type='text' variant='outlined' />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                <Grid item xs={12} >
                                    <Field fullWidth component={TextField} name='company_name' label="Company Name" type='text' variant='outlined' />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                <Grid item xs={12} sm={6} >
                                    <Field fullWidth component={TextField} onChange={(e) => { filterNumber({ e, handleChange }) }} name='its_no' label="ITS.No" type='text' variant='outlined' />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Field fullWidth component={TextField} name='email' label="Email" type='text' variant='outlined' />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                <Grid item xs={12} sm={6} >
                                    <Field fullWidth component={TextField} onChange={(e) => { filterNumber({ e, handleChange, include: ['+'] }) }} name='phone_no' label="Mobile No" type='tel' variant='outlined' />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Field fullWidth component={TextField} onChange={(e) => { filterNumber({ e, handleChange }) }} name='loanAllocation.amount' label="Qardan Amount" type='text' variant='outlined' InputProps={{ startAdornment: <InputAdornment position="start">£</InputAdornment> }} />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                <Grid item xs={12} sm={6} >
                                    {/* <Field fullWidth component={TextField} name='loanAllocation.date' label="Loan Allocation Date" type='text' variant='outlined' /> */}
                                    <Field fullWidth component={KeyboardDatePicker} name='loanAllocation.date' label="Payment Date" format="DD/MM/yyyy" variant='outlined' InputLabelProps={{ shrink: true, }} KeyboardButtonProps={{ 'aria-label': 'change date' }} />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    {/* <Field fullWidth component={TextField} name='loanAllocation.date' label="Loan Allocation Date" type='text' variant='outlined' /> */}
                                    <Field fullWidth component={KeyboardDatePicker} name='loanAllocation.repayment_date' label="Repayment Start Date" format="DD/MM/yyyy" variant='outlined' InputLabelProps={{ shrink: true, }} KeyboardButtonProps={{ 'aria-label': 'change repayment_date' }} />
                                </Grid>
                            </Grid>

                            <Grid container item spacing={2} style={{ marginBottom: "9px" }} >
                                <Grid item xs={12} >
                                    <Field fullWidth component={TextField} onChange={(e) => { filterNumber({ e, handleChange }) }} name='loanAllocation.repayment_duration_in_months' label="Repayment Duration In Months" type='text' variant='outlined' />
                                </Grid>
                            </Grid>

                            {/* <br /> */}
                            <Grid item container justify='center' >
                                <Button
                                    variant='contained' size='medium'
                                    disabled={isSubmitting} color='primary' type="submit" >
                                    {isSubmitting ? <div><CircularProgress color='inherit' /></div> : <div>{action === "ADD" ? "Add" : "Update"}</div>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </MuiPickersUtilsProvider>
            )
            }
        </Formik >

    )
}

export default QardanApplicantForm;
