import React, { FC, useEffect } from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Button, PageLayout, Seo } from '../../components';
import MainUI from '../../components/admin';
import { screenWidths } from '../../theme/css-generators';
import { useAppDispatch, useAppSelector } from '../../reduxStore/store';
import { logoutFromSbqhPortal } from '../../reduxStore/slices/sbqhPortalSlice';
import { RouteComponentProps } from '@reach/router';
import { BodyWrap } from "../taher-scheme";
import { LOGIN_PAGE_LINK } from './login';
import { navigate } from 'gatsby-link';
import config from '../../../app-config.json';
import { siteMetadata } from '../../../gatsby-config';

const PAGE_TITLE = config.pages.ADMIN.title;

//////////////////////////////////  component ////////////////////////////////
const App: FC<RouteComponentProps> = () => {
    const dispatch = useAppDispatch();
    const widthUnder500 = useMediaQuery(`(min-width: ${screenWidths._500px})`);
    const { authToken, fetchingStatus, error, userDetails, isAuthenticated } = useAppSelector(s => s.sbqhPortal);
    const handleLogout = () => {
        dispatch(logoutFromSbqhPortal())
    }

    useEffect(() => {
        if (!isAuthenticated) { navigate(LOGIN_PAGE_LINK) }
    }, [isAuthenticated])

    if (!isAuthenticated) {
        return <div></div>
    }

    return (
        <PageLayout>
            <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} indexing={false} />
            <BodyWrap >
                <Grid container justify='space-between' alignItems='center' >
                    <Typography variant='h2' color='inherit' >Admin Portal</Typography>
                    {widthUnder500 ?
                        <Button color='primary' onClick={handleLogout} >SIGN OUT</Button> :
                        <Button color='primary' size='small' onClick={handleLogout} >SIGN OUT</Button>
                    }
                </Grid >
                <hr />
                <br />
                <MainUI />
            </BodyWrap>
        </PageLayout>
    )
}

export default App


// import React, { FC, useEffect } from 'react';
// import { PageProps, navigate } from 'gatsby';
// import { Grid, Typography, useMediaQuery } from '@material-ui/core';
// import { PageLayout, Button, Seo } from '../../components';
// import config from '../../../app-config.json';
// import { LoginForm } from '../../components/Form';
// // import LoginForm from '../components/Form/LoginForm';
// import * as s from '../../style.pages/index.style';
// import { Router, RouteComponentProps } from '@reach/router';
// import { LOGIN_PAGE_LINK } from './login';
// import { RootStateType, useAppDispatch } from '../../reduxStore/store';
// import { getSbqhPortalDetails, loginToSBQHPortal, logoutFromSbqhPortal } from '../../reduxStore/slices/sbqhPortalSlice';
// import { siteMetadata } from '../../../gatsby-config';
// import App from './App';
// import { screenWidths } from '../../theme/css-generators';

// const PAGE_TITLE = config.pages.ADMIN.title;
// const PAGE_PATH = config.pages.ADMIN.path;

// //////////////////////////////////  component ////////////////////////////////
// const Admin: FC<PageProps> = ({ }) => {
//     const dispatch = useAppDispatch();
//     const widthUnder500 = useMediaQuery(`(min-width: ${screenWidths._500px})`);
//     const handleLogout = () => {
//         dispatch(logoutFromSbqhPortal())
//     }

//     return (
//         <PageLayout>
//             <Seo title={`${PAGE_TITLE} | ${siteMetadata.title}`} indexing={false} />
//             <s.BodyWrap >
//                 <br />
//                 <Grid container justify='space-between' alignItems='center' >
//                     <Typography variant='h2' color='inherit' >{PAGE_TITLE} Portal</Typography>
//                     {widthUnder500 ?
//                         <Button color='primary' onClick={handleLogout} >SIGN OUT</Button> :
//                         <Button color='primary' size='small' onClick={handleLogout} >SIGN OUT</Button>
//                     }
//                 </Grid >

//                 <hr /><br /> <br />
//                 <Router basepath={PAGE_PATH}>
//                     <App path="/" />
//                     <Login path="/login" />
//                     {/* <User path="/:username" /> */}
//                 </Router >

//             </s.BodyWrap>
//         </PageLayout>
//     )
// }

// export default Admin;


// const Login: FC<RouteComponentProps> = ({ }) => {
//     // const auth = useSelector((state: RootStateType) => state.hsAuth);
//     // console.log(auth);

//     // useEffect(() => {
//     //     if (auth.token) { qryHandler.ReadStatus(auth.token); }
//     //     else if (auth.isAuthenticated === false) { navigate(LOGIN_FORM_LINK) }
//     // }, [])

//     return (
//         <>
//             <LoginForm handleSubmit={async (val) => { console.log(val) }} />
//             <br />
//         </>
//     )
// }


// // const User: FC<RouteComponentProps> = ({ path, }) => {
// //     const param = useParams();
// //     console.log(param)
// //     // const auth = useSelector((state: RootStateType) => state.hsAuth);
// //     // console.log(auth);

// //     // useEffect(() => {
// //     //     if (auth.token) { qryHandler.ReadStatus(auth.token); }
// //     //     else if (auth.isAuthenticated === false) { navigate(LOGIN_FORM_LINK) }
// //     // }, [])

// //     return (
// //         <>
// //             <Typography variant='h4' >{param.username}</Typography>
// //             <br />
// //         </>
// //     )
// // }