import React, { FC, memo, useEffect, useState } from 'react';
import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ArrowBack, Delete, } from '@material-ui/icons';
import { SquareEditOutline } from 'mdi-material-ui';
import { QardanApplicant, UpdateQardanApplicantInput, UserType } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import Button from '../../Button';
import QardanApplicantForm, { FormFields as QardanApplicantFormFields } from '../../Form/QardanApplicantForm';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { changeQardanApplicantPasswordBySBQHuser, changeQardanApplicantDetails, deleteQardanApplicant, getAllQardanApplicants } from '../../../reduxStore/slices/sbqhPortalSlice';
import { ErrorDialog, SuccessDialog, ConfirmationDialog } from '../../Alert';
import { GetQardanApplicantInfo } from '../../../graphql/queries';
import { GraphQLResult } from '../../../typedefs';
import { formatNum } from '../../../helper';
import moment from 'moment';
import ResetPassword from '../../Form/ResetPassword';

interface ApplicantDetailDialogProps {
    applicantId: string;
    open: boolean;
    handleClose: () => void;
}

const ApplicantDetailDialog: FC<ApplicantDetailDialogProps> = ({ applicantId, open, handleClose }) => {
    const [data, setData] = useState<QardanApplicant | null>(null)
    const [isDeleting, setDeleting] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [dialogView, setDialogView] = useState<"EDIT" | "VIEW" | "RESET_PASSWORD">("VIEW");
    const dispatch = useAppDispatch();
    const { data: applicantsList, totalCount, filterOptions } = useAppSelector(state => state.sbqhPortal.qardan_applicants_data);
    const { pageSize, pageNumber } = filterOptions;

    const _handleClose = () => { handleClose(); setOnViewDialogview() }

    const loadData = async () => {
        // const applicant = applicantsList?.filter(v => v.id === applicantId)[0];
        setFetching(true);
        try {
            const { data } = await GetQardanApplicantInfo(UserType.SbqhUser, { applicantId });
            data?.getQardanApplicantInfo && setData(data?.getQardanApplicantInfo);
        } catch (error) {
            console.log("Error.GetQardanApplicantInfo ==>", error);
        }
        setFetching(false);
    }
    const handleDeleteAccount = async () => {
        setDeleting(true);
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, delete it!" })
        if (!isConfirmed) { setDeleting(false); return }
        const { res } = await new Promise<{ err, res }>((resolve) => {
            dispatch(deleteQardanApplicant({
                id: applicantId, callback: (err, res) => {
                    resolve({ err, res })
                }
            }))
        })
        setDeleting(false);
        res && _handleClose()
    }
    /*use to set the dialog screen to Edit detail form*/
    const setOnEditDialogview = () => { setDialogView("EDIT") }
    /*use to set the dialog screen to View details*/
    const setOnViewDialogview = () => { setDialogView("VIEW") }
    /*use to set the dialog screen to Reset Password*/
    const setOnResetPasswordDialogview = () => { setDialogView("RESET_PASSWORD") }

    useEffect(() => {
        !isFetching && data?.id !== applicantId && loadData()
    }, [applicantId])

    return (
        <Dialog open={open} onClose={_handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
            {dialogView === "VIEW" && <ViewDetails
                handleDeleteAccount={handleDeleteAccount}
                data={data}
                isDeleting={isDeleting}
                isFetchingData={isFetching}
                handleEdit={setOnEditDialogview}
                handleResetPassword={setOnResetPasswordDialogview}
            />}

            {dialogView === "EDIT" && data && <EditDetails
                data={data}
                handleBack={setOnViewDialogview}
                onChangeData={loadData}
            />}

            {dialogView === "RESET_PASSWORD" && <UpdatePassword
                applicantId={data?.id || ""}
                handleBack={setOnViewDialogview}
            />}

        </Dialog>
    )
}

export default memo(ApplicantDetailDialog);


interface ViewDetailsProps {
    handleDeleteAccount: () => void;
    data: QardanApplicant | null;
    isDeleting: boolean;
    isFetchingData: boolean;
    handleEdit: () => void;
    handleResetPassword: () => void;
}
const ViewDetails: FC<ViewDetailsProps> = ({ handleDeleteAccount, data, isDeleting, isFetchingData, handleEdit, handleResetPassword }) => {
    return (
        <>
            <DialogTitle style={{ paddingBottom: "5px" }}  >
                <Grid container justify='space-between' alignItems='center' style={{ color: 'inherit' }} >
                    <b>Applicant Details</b>
                    <IconButton title='delete account' disabled={isDeleting} onClick={handleDeleteAccount} ><Delete /></IconButton>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {(data && !isFetchingData) ?
                    <>
                        <Typography variant='body2' ><b>File No:</b> {data.id.toUpperCase()}</Typography>
                        <Typography variant='body2' ><b>Title:</b> {data.title}</Typography>
                        <Typography variant='body2' ><b>First Name:</b> {data.first_name}</Typography>
                        <Typography variant='body2' ><b>Last Name:</b> {data.last_name}</Typography>
                        {data.company_name && <Typography variant='body2' ><b>Company Name:</b> {data.company_name}</Typography>}
                        <Typography variant='body2' ><b>ITS.No:</b> {data.its_no || ""}</Typography>
                        <Typography variant='body2' ><b>Email:</b> <a href={`mailto:${data.email}`}>{data.email}</a></Typography>
                        <Typography variant='body2' ><b>Phone:</b> <a href={`tel:${data.phone_no}`}>{data.phone_no}</a></Typography>

                        <Typography style={{ paddingTop: "5px" }} ><u><b>Qardan Allocation:</b></u></Typography>
                        <Typography variant='body2' ><b>Payment Date:</b> {moment(data.loanAllocation?.date).format("DD-MMM-YYYY")}</Typography>
                        <Typography variant='body2' ><b>Repayment Start Date:</b> {moment(data.loanAllocation?.repayment_date).format("DD-MMM-YYYY")}</Typography>
                        <Typography variant='body2' ><b>Repayment Duration:</b> {data.loanAllocation?.repayment_duration_in_months} Months</Typography>
                        <Typography variant='body2' ><b>Repayment Status:</b> {data.loanAllocation?.status.replace(/_/g, ' ')}</Typography>
                        <Typography variant='body2' ><b>Qardan Received:</b> £{formatNum(data.loanRepaid)}</Typography>
                        {
                            (data.loanRepaid || 0) > (data.loanAllocation?.amount || 0) ?
                                <Typography variant='body2' style={{ color: 'green' }} ><b>Overpayment:</b> £{formatNum(Math.abs((data.loanAllocation?.amount || 0) - (data.loanRepaid || 0)))}</Typography> :
                                <Typography variant='body2' style={{ color: 'red' }} ><b>Qardan Outstanding:</b> £{formatNum(Math.abs((data.loanAllocation?.amount || 0) - (data.loanRepaid || 0)))}</Typography>
                        }
                        <Typography variant='body2' ><b>Qardan Given:</b> £{formatNum(data.loanAllocation?.amount)}</Typography>
                        {/* <Typography><b>Account balance:</b> £{formatNum(data.balance_amount)}</Typography> */}
                    </> :
                    <>
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                        <Skeleton animation='wave' variant='rect' height={14} width='80%' style={{ marginBottom: '10px' }} />
                    </>
                }
            </DialogContent>

            <DialogActions>
                {/* <Button onClick={handleClose} color="primary">
                    Save
                </Button> */}
                <Button color="primary" disabled={isDeleting} onClick={handleResetPassword}>
                    <Grid container alignItems='center' style={{ padding: '3px' }} >Reset Password</Grid>
                </Button>
                <Button color="primary" disabled={isDeleting} onClick={handleEdit}>
                    <Grid container alignItems='center' ><SquareEditOutline /><span>Edit</span></Grid>
                </Button>
            </DialogActions>
        </>
    )
}


interface EditDetailsProps {
    // handleUpdateAccount: () => void;
    data: QardanApplicant;
    onChangeData?: (val: UpdateQardanApplicantInput) => void
    handleBack: () => void;
}
const EditDetails: FC<EditDetailsProps> = ({ handleBack, data, onChangeData }) => {

    const dispatch = useAppDispatch();
    // const isChangingHSuserDetails = useAppSelector(s => s.sbqhPortal.fetchingStatus.changeHSuserDetails);
    const handleSubmit = async ({ first_name, last_name, its_no, email, phone_no, title, company_name, loanAllocation }: QardanApplicantFormFields) => {
        if (!data) return;
        const input: UpdateQardanApplicantInput = {
            // userType: UserType.QardanUser2,
            id: data.id, first_name, last_name, email, phone_no, company_name,
            its_no: Number(its_no),
            title,
            loanAllocation: {
                amount: Number(loanAllocation.amount),
                repayment_duration_in_months: Number(loanAllocation.repayment_duration_in_months),
                date: moment(loanAllocation.date).toDate().getTime(),
                repayment_date: moment(loanAllocation.repayment_date).toDate().getTime()
            }
        }
        await new Promise((resolve, reject) => {
            data && dispatch(changeQardanApplicantDetails({
                input,
                callback: (err, res) => { resolve({ res, err }); }
            }))
        })
        data && onChangeData && onChangeData(input);
        handleBack();
    }

    return (
        <>
            <DialogTitle style={{ paddingBottom: "0px" }}  >
                <IconButton onClick={handleBack} ><ArrowBack /></IconButton>
            </DialogTitle>

            <DialogContent>
                <QardanApplicantForm
                    action='UPDATE'
                    // initialValues={{ first_name: data?.first_name, its_no: data?.its_no?.toString() || "", last_name: data?.last_name, email: data?.email, phone_no: data?.phone_no }}
                    initialValues={{
                        id: data.id, email: data.email, title: data.title,
                        first_name: data.first_name, its_no: data.its_no?.toString(),
                        last_name: data.last_name, phone_no: data.phone_no,
                        loanAllocation: {
                            amount: `${data.loanAllocation?.amount || 0}`,
                            date: moment(data.loanAllocation?.date),
                            repayment_date: moment(data.loanAllocation?.repayment_date),
                            repayment_duration_in_months: `${data.loanAllocation?.repayment_duration_in_months || 0}`,
                        }
                    }}
                    handleSubmit={handleSubmit}
                />
            </DialogContent>
        </>
    )
}


const UpdatePassword = ({ handleBack, applicantId }) => {
    const dispatch = useAppDispatch();
    const handleSubmit = async ({ password }) => {
        if (!applicantId) return;
        await new Promise((resolve, reject) => {
            dispatch(changeQardanApplicantPasswordBySBQHuser({
                applicantId, newPassword: password,
                callback: (err, res) => { err && reject(err); res && resolve(res); }
            }))
        })
        handleBack();
    }

    return (<>
        <DialogTitle style={{ paddingBottom: "0px" }}  >
            <IconButton onClick={handleBack} ><ArrowBack /></IconButton>
        </DialogTitle>

        <DialogContent>
            <ResetPassword handleSubmit={handleSubmit} />
        </DialogContent>
    </>)
}