import React, { FC, FormEvent, FormEventHandler, useEffect, useState } from "react";
import { ComparisonOperators, FilterField, HSuserBasicInfo, Order, QardanApplicant, QardanInstallment } from "../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/store";
import { addQardanInstallment, deleteQardanInstallment, getAllQardanInstallments } from "../../../reduxStore/slices/sbqhPortalSlice";
import { Grid, Typography, Dialog, TextField, MenuItem, CircularProgress, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { Add, Search } from '@material-ui/icons';
import { Button, Table } from "../..";
import QardanInstallmentForm, { FormFields as QardanInstallmentFormFields } from './QardanInstallmentForm';
import { ConfirmationDialog, ErrorDialog, SuccessDialog } from "../../Alert";
import { useDispatch } from "react-redux";
import { tableLoading } from '../HS_AccountsTab';
import { formatNum, TransactionDescription } from "../../../helper";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import ApplicantDetailDialog from "../Qardan_ApplicantsTab/ApplicantDetailDialog";


const sortFilters: { [P in keyof QardanInstallment]?: string } = {
    id: "ID",
    date: "Date",
    applicant_id: "File No",
    description: "Description"
}

const Qardan_Installments: FC = ({ }) => {
    const { data, totalCount, totalLoanAllocated, totalLoanPaid, totalLoanPending, filterOptions } = useAppSelector((state) => state.sbqhPortal.qardan_installments);
    const isFetching = useAppSelector((state) => state.sbqhPortal.fetchingStatus.getAllQardanInstallments);
    const dispatch = useAppDispatch();
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months'));
    const [dateTo, setDateTo] = useState(moment());
    const [sortKey, setSortKey] = useState<keyof QardanInstallment>('id');
    const [rowDetails, setRowDetails] = useState<QardanInstallment | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { pageNumber, pageSize, } = filterOptions;

    const loadData = async () => {
        dispatch(getAllQardanInstallments(filterOptions))
    }
    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        // console.log("pageNumber", pageNumber, "pageSize", pageSize)
        dispatch(getAllQardanInstallments({ ...filterOptions, pageNumber, pageSize }))
    }
    const handleRemoveFilter = () => {
        dispatch(getAllQardanInstallments({ pageNumber, pageSize }))
    }
    const handleApplyFilter: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        // const _dateFrom = e.currentTarget.elements.item(0) as HTMLInputElement
        // const _dateTo = e.currentTarget.elements.item(1) as HTMLInputElement
        const _dateFrom = dateFrom.format("YYYY-MM-DD");
        const _dateTo = dateTo.format("YYYY-MM-DD");
        const sortBy = { fields: [sortKey], order: Order.Desc }
        // console.log("elems ===>", e.currentTarget.elements.item(0));
        // console.log("dates ===>", _dateFrom, _dateTo);
        const filterBy: FilterField[] = [];
        if (_dateFrom) {
            filterBy[0] = { field: "date", operator: ComparisonOperators.Gte, value: _dateFrom };
        }
        if (_dateTo) {
            filterBy[1] = { field: "date", operator: ComparisonOperators.Lte, value: _dateTo };
        }

        dispatch(getAllQardanInstallments({ ...filterOptions, filterBy, sortBy, pageNumber: 1 }))
    }

    const OpenApplicantDetailDialog = (values: QardanInstallment) => {
        setOpenDialog(true);
        setRowDetails(values)
    }

    useEffect(() => {
        !isFetching && !data && loadData()
    }, [])

    return (
        <Grid >
            {/* <br />
            <Button color='primary' variant='outline' size='small' disabled={isLoadingPdf}
                onClick={async () => {
                    setLoadingPdf(true);
                    await generatePdf<"Transactions">({
                        type: "Transactions",
                        param: { filter: { ...filterOptions, pageNumber: 1, pageSize: totalCount } }
                    })
                    setLoadingPdf(false);
                }}
            >
                {isLoadingPdf ? "Loading..." : "Generate PDF"}
            </Button> */}
            <br />
            <Grid component='form' onSubmit={handleApplyFilter} >
                <Grid container justify='flex-start' alignItems='center' wrap='wrap'>
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <KeyboardDatePicker style={{ margin: "15px" }} format="DD/MM/yyyy" value={dateFrom} onChange={date => setDateFrom(date!)} label="Date From" />
                        <KeyboardDatePicker style={{ margin: "15px" }} format="DD/MM/yyyy" value={dateTo} onChange={date => setDateTo(date!)} label="Date To" />
                    </MuiPickersUtilsProvider>
                    <Grid ><TextField style={{ marginLeft: '5px' }} value={sortKey} onChange={(e) => { setSortKey(e.target.value as any) }} variant="outlined" select type='select' size="small" label='Sort By'
                        children={Object.entries(sortFilters).map(([key, value], idx) => (
                            <MenuItem key={idx} value={key} >{value}</MenuItem>
                        ))}
                    /></Grid>
                </Grid>
                <Grid container justify='flex-end' >
                    <Button style={{ margin: "10px" }} type='submit' color='primary' >Apply Filter</Button>
                    <Button style={{ margin: "10px" }} type="button" color='primary' onClick={handleRemoveFilter} >Get All Results</Button>
                </Grid>
            </Grid>
            <hr />
            <br />
            <Grid >
                <Typography variant='h6' style={{ color: 'green' }} ><b>Qardan Received:</b> £{formatNum(totalLoanPaid)}</Typography>
                <Typography variant='h6' style={{ color: 'red' }} ><b>Qardan Outstanding:</b> £{formatNum(totalLoanPending)}</Typography>
                <Typography variant='h6'  ><b>Qardan Given:</b> £{formatNum(totalLoanAllocated)}</Typography>
            </Grid>
            <Grid container justify='flex-end' >
                <AddQardanInstallmentForm onSuccess={loadData} />
            </Grid>
            <br />

            {rowDetails &&
                <ApplicantDetailDialog open={openDialog} handleClose={() => { setOpenDialog(false) }} applicantId={rowDetails.applicant_id} />}

            <Table
                label={<Typography variant='h4' align='center' >Qardan Hasana Installments</Typography>}
                columns={[
                    { id: "id", minWidth: 70, align: "left", label: "ID", },
                    { id: "applicant_id", minWidth: 130, align: "left", label: "File.No", format: (val: string, rowValue) => <p style={{ cursor: "pointer", color: "purple", textDecoration: "underline" }} onClick={() => { OpenApplicantDetailDialog(rowValue) }} >{val.toUpperCase()}</p> },
                    { id: "date", minWidth: 130, align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                    { id: "amount", minWidth: 140, align: "left", label: "Amount", format: (val: number) => <p style={{ color: val < 0 ? "red" : 'green' }} >£{formatNum(Math.abs(val))}</p> },
                    { id: "description", minWidth: 150, align: "left", label: "Description", format: (val: string) => TransactionDescription[val] || val },
                    { id: "id", align: "left", minWidth: 150, label: "Action", format: (_, { id, }) => <DeleteQardanInstallment installmentId={id} onDelete={loadData} /> },
                ]}
                rows={data || []}
                totalRows={totalCount}
                pageNumber={filterOptions.pageNumber - 1}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no transactions to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />
        </Grid>
    )
}

export default Qardan_Installments;


const AddQardanInstallmentForm: FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: QardanInstallmentFormFields) => {
        // console.log(values);
        let { applicant_id, amount, dateTime, description } = values;
        // console.log("dateTime", dateTime);
        const date = moment(dateTime).toDate().getTime()
        // console.log("date", date);

        let _amount = Number(amount)
        // if (TransactionType.PaidOl === (description as TransactionType) && _amount > 0) {
        //     _amount = -_amount
        // }
        // console.log(date, dateTime);
        if (!applicant_id) { return }
        try {
            const { res } = await new Promise<{ err, res }>((resolve, reject) => {
                dispatch(addQardanInstallment({
                    input: { applicant_id: applicant_id!, amount: _amount, date, description }, callback: (err, res) => {
                        err && reject(err)
                        resolve({ res, err })
                    }
                }));
            })
            handleClose()
            onSuccess && onSuccess();
            await SuccessDialog("Transaction Added", "New transaction successfully added.");
        } catch (error: any) {
            await ErrorDialog("Request Failed", error.errors[0].message);
        }
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    return (
        <>
            <Button color='primary' size='small' onClick={handleClickOpen}  ><Grid container alignItems='center' ><Add /><span>Add Transaction</span></Grid></Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <QardanInstallmentForm action="ADD" handleSubmit={handleSubmit} />
            </Dialog>
        </>
    )
}


const DeleteQardanInstallment: FC<{ installmentId: number, onDelete?: () => void }> = ({ installmentId, onDelete }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    // const isDeletingTransaction = useAppSelector(s => s.sbqhPortal.fetchingStatus.deleteTransaction)
    const handleDelete = async () => {
        setLoading(true);
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, delete it!" })
        if (!isConfirmed) { setLoading(false); return }
        const { err, res } = await new Promise<{ err, res }>((resolve, reject) => {
            dispatch(deleteQardanInstallment({
                id: installmentId, callback: (err, res) => {
                    resolve({ err, res });
                }
            }))
        })
        // if (err) { setLoading(false); }
        if (res && onDelete) { onDelete() }
        setLoading(false)

    }

    return (
        <span style={{ position: "relative", bottom: 25 }} >
            {loading ?
                <CircularProgress style={{ position: "absolute" }} /> :
                <IconButton style={{ position: "absolute" }} title='Delete transaction' onClick={handleDelete} ><Delete /></IconButton>
            }
        </span>
    )
}