import React, { FC, useState } from 'react';
import LeftPane from './LeftPane';
import { Grid, makeStyles } from '@material-ui/core';
import HS_AccountsTab from './HS_AccountsTab';
import HS_WithdrawalRequestTab from './HS_WithdrawalRequestTab';
import SBQH_ProfileTab from './SBQH_ProfileTab';
import SBQH_AdminUsersTab from './SBQH_AdminUsersTab';
import HS_TransactionsTab from './HS_TransactionsTab';
import HSaccountsWithBalances from './AnalyticsTabs/HSaccountsWithBalances';
import DormantHSaccounts from './AnalyticsTabs/DormantHSaccounts';
import BankReconciliation from './AnalyticsTabs/BankReconciliation';
import Qardan_ApplicantSearch from './AnalyticsTabs/Qardan_ApplicantSearch';
import Qardan_ApplicantsTab from './Qardan_ApplicantsTab';
import Qardan_Installments from './Qardan_Installments';
import { useAppSelector } from '../../reduxStore/store';
import Qardan_ApplicantsWithOutstandingBalances from './AnalyticsTabs/Qardan_ApplicantsWithOutstandingBalances';


interface Props { }
const Main: FC<Props> = ({ }) => {
    const classes = useStyles();
    const [tab, setTab] = useState<number>(1);
    const userAccess = useAppSelector(s => s.sbqhPortal.userDetails?.access)

    return (
        <Grid justify='space-between' alignItems='flex-start' container className={classes.container} >
            <Grid>
                <LeftPane
                    items={[
                        {
                            listTitle: "SBQH Management",
                            listItems: [
                                { title: "Profile", id: 1 },
                                { title: "Admin Users", id: 2 },
                            ]
                        },
                        {
                            listTitle: "HS Management",
                            listItems: [
                                { title: "Accounts", id: 3 },
                                { title: "Transactions", id: 4 },
                                { title: "Withdrawal Requests", id: 5 },
                                { title: "Account Search", id: 6 },
                                { title: "Accounts With Balances", id: 7 },
                                { title: "Dormant Accounts", id: 8 },
                            ]
                        },
                        {
                            listTitle: "Qardan Hasana Management",
                            listItems: [
                                { title: "Applicants", id: 9 },
                                { title: "Transactions", id: 10 },
                                { title: "Applicant Search", id: 11 },
                                { title: "Applicants With Outstanding Balances", id: 12 },
                            ]
                        },
                    ]}
                    defaultselectedItemId={tab}
                    onListItemClick={setTab}
                />
            </Grid>
            <Grid style={{ flex: '.97', overflowX: 'hidden' }} >
                {tab === 1 && <SBQH_ProfileTab />}
                {tab === 2 && !!userAccess?.SBQH_Admin_Users.read && <SBQH_AdminUsersTab />}

                {tab === 3 && !!userAccess?.HS_Accounts.read && <HS_AccountsTab />}
                {tab === 4 && !!userAccess?.HS_Transactions.read && <HS_TransactionsTab />}
                {tab === 5 && !!userAccess?.HS_Withdrawal_Requests.read && <HS_WithdrawalRequestTab />}
                {tab === 6 && !!userAccess?.HS_Transactions.read && <BankReconciliation />}
                {tab === 7 && !!userAccess?.HS_Transactions.read && <HSaccountsWithBalances />}
                {tab === 8 && !!userAccess?.HS_Transactions.read && <DormantHSaccounts />}

                {tab === 9 && !!userAccess?.Qardan_Applicants.read && <Qardan_ApplicantsTab />}
                {tab === 10 && !!userAccess?.Qardan_Installments.read && <Qardan_Installments />}
                {tab === 11 && !!userAccess?.Qardan_Installments.read &&
                    !!userAccess?.Qardan_Applicants.read &&
                    < Qardan_ApplicantSearch />}
                {tab === 12 && !!userAccess?.Qardan_Applicants.read && <Qardan_ApplicantsWithOutstandingBalances />}

            </Grid>
        </Grid>
    )
}

export default Main;


const useStyles = makeStyles((theme) => ({
    container: {
        // backgroundColor: "whitesmoke",
        width: '100%',
        minHeight: "500px",
    }
}))