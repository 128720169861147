import React, { FC, useState, useEffect, FormEventHandler, FormEvent } from 'react';
import Table from '../../Table';
import { Grid, Typography, Dialog, TextField, IconButton, CircularProgress, MenuItem } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { } from '../../../graphql/mutations';
import { ComparisonOperators, FilterField, Order, Transaction, } from '../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import Button from '../../Button';
import HStransactionForm, { FormFields as HStransactionFormFields } from './HStransactionForm';
import { useAppDispatch, useAppSelector } from '../../../reduxStore/store';
import { getAllHStransactions, addTransaction, deleteTransaction } from '../../../reduxStore/slices/sbqhPortalSlice';
import { ConfirmationDialog, ErrorDialog, SuccessDialog } from '../../Alert';
import { tableLoading } from '../HS_AccountsTab';
import moment from 'moment';
import { formatNum, TransactionDescription } from '../../../helper';
import { Delete } from '@material-ui/icons';
import { TransactionType } from '../../../typedefs';
// import appconfig from '../../../../app-config.json';
import { generatePdf } from '../../../services/generatePdf';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';

const sortFilters: { [P in keyof Transaction]?: string } = {
    id: "ID",
    date: "Date",
}


const HS_Transactions: FC<{}> = ({ }) => {
    const [isLoadingPdf, setLoadingPdf] = useState(false);
    const { data, totalCount, amountIn, amountOut, filterOptions } = useAppSelector((state) => state.sbqhPortal.hs_transactions_data);
    const { pageNumber, pageSize, } = filterOptions;
    const isFetching = useAppSelector((state) => state.sbqhPortal.fetchingStatus.getAllHStransactions);
    const numFrmt = new Intl.NumberFormat();
    const [dateFrom, setDateFrom] = useState(moment().subtract(1, 'months'));
    const [dateTo, setDateTo] = useState(moment());
    const [sortKey, setSortKey] = useState<keyof Transaction>('id');

    const dispatch = useAppDispatch();

    const loadData = async () => {
        dispatch(getAllHStransactions(filterOptions))
    }
    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        // console.log("pageNumber", pageNumber, "pageSize", pageSize)
        dispatch(getAllHStransactions({ ...filterOptions, pageNumber, pageSize }))
    }
    // const handleSearchBySort = (e: FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     const elem = e.currentTarget.elements.item(0) as HTMLInputElement
    //     // console.log(filterKey)
    //     // const id = elem.value;
    //     const sortBy = { fields: [sortKey], order: Order.Desc }
    //     dispatch(getAllHStransactions({ ...filterOptions, sortBy }))
    // }

    const handleRemoveFilter = () => {
        dispatch(getAllHStransactions({ pageNumber, pageSize }))
    }

    const handleApplyFilter: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        // const _dateFrom = e.currentTarget.elements.item(0) as HTMLInputElement
        // const _dateTo = e.currentTarget.elements.item(1) as HTMLInputElement
        const _dateFrom = dateFrom.format("YYYY-MM-DD");
        const _dateTo = dateTo.format("YYYY-MM-DD");
        const sortBy = { fields: [sortKey], order: Order.Desc }
        // console.log("elems ===>", e.currentTarget.elements.item(0));
        // console.log("dates ===>", _dateFrom, _dateTo);
        const filterBy: FilterField[] = [];
        if (_dateFrom) {
            filterBy[0] = { field: "date", operator: ComparisonOperators.Gte, value: _dateFrom };
        }
        if (_dateTo) {
            filterBy[1] = { field: "date", operator: ComparisonOperators.Lte, value: _dateTo };
        }

        dispatch(getAllHStransactions({ ...filterOptions, filterBy, sortBy, pageNumber: 1 }))
    }

    useEffect(() => {
        !isFetching && !data && loadData()
    }, [])

    return (
        <Grid >
            <br />
            <Button color='primary' variant='outline' size='small' disabled={isLoadingPdf}
                onClick={async () => {
                    setLoadingPdf(true);
                    await generatePdf<"Transactions">({
                        type: "Transactions",
                        param: { filter: { ...filterOptions, pageNumber: 1, pageSize: totalCount } }
                    })
                    setLoadingPdf(false);
                }}
            >
                {isLoadingPdf ? "Loading..." : "Generate PDF"}
            </Button>
            <br />
            <Grid component='form' onSubmit={handleApplyFilter} >
                <Grid container justify='flex-start' alignItems='center' wrap='wrap'>
                    <MuiPickersUtilsProvider utils={MomentUtils} >
                        <KeyboardDatePicker style={{ margin: "15px" }} format="DD/MM/yyyy" value={dateFrom} onChange={date => setDateFrom(date!)} label="Date From" />
                        <KeyboardDatePicker style={{ margin: "15px" }} format="DD/MM/yyyy" value={dateTo} onChange={date => setDateTo(date!)} label="Date To" />
                    </MuiPickersUtilsProvider>
                    <Grid ><TextField style={{ marginLeft: '5px' }} value={sortKey} onChange={(e) => { setSortKey(e.target.value as any) }} variant="outlined" select type='select' size="small" label='Sort By'
                        children={Object.entries(sortFilters).map(([key, value], idx) => (
                            <MenuItem key={idx} value={key} >{value}</MenuItem>
                        ))}
                    /></Grid>
                </Grid>
                <Grid container justify='flex-end' >
                    <Button style={{ margin: "10px" }} type='submit' color='primary' >Apply Filter</Button>
                    <Button style={{ margin: "10px" }} type="button" color='primary' onClick={handleRemoveFilter} >Get All Results</Button>
                </Grid>
            </Grid>
            <hr />
            <br />
            <Grid >
                <Typography variant='h6' style={{ color: 'green' }} ><b>Money In:</b> £{formatNum(amountIn)}</Typography>
                <Typography variant='h6' style={{ color: 'red' }} ><b>Money Out:</b> £{formatNum(amountOut)}</Typography>
                <Typography variant='h6' ><b>Total Amount:</b> £{formatNum(amountIn - amountOut)}</Typography>
            </Grid>
            <Grid container justify='flex-end' >
                <AddHStransactionForm />
            </Grid>
            <br />

            <Table
                label={<Typography variant='h4' align='center' >Husaini Scheme Transactions</Typography>}
                columns={[
                    { id: "id", minWidth: 150, align: "left", label: "Transaction ID", },
                    { id: "hsUserId", minWidth: 130, align: "left", label: "Acc ID", format: (val: string) => val?.toUpperCase() },
                    { id: "date", minWidth: 130, align: "left", label: "Date", format: (val: string) => val.length > 24 ? val : moment(val).format("DD MMM YY") },
                    { id: "amount", minWidth: 140, align: "left", label: "Amount", format: (val: number) => <p style={{ color: val < 0 ? "red" : 'green' }} >£{formatNum(Math.abs(val))}</p> },
                    { id: "description", minWidth: 150, align: "left", label: "Description", format: (val: string) => TransactionDescription[val] || val },
                    { id: "id", align: "left", minWidth: 150, label: "Action", format: (_, { id, }) => <DeleteTransaction transaction_id={id} /> },
                ]}
                rows={data || []}
                totalRows={totalCount}
                pageNumber={pageNumber! - 1}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no transactions to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />

        </Grid>
    )
}

export default HS_Transactions;


const FilterBox: FC = () => {
    const { data, totalCount, filterOptions } = useAppSelector((state) => state.sbqhPortal.hs_transactions_data);
    const { pageNumber, pageSize } = filterOptions;
    return (
        <Grid>
            <TextField label="Date From" type="date" InputLabelProps={{ shrink: true }} />
        </Grid>
    )
}

const AddHStransactionForm: FC = ({ }) => {
    const dispatch = useAppDispatch();

    const handleSubmit = async (values: HStransactionFormFields) => {
        // console.log(values);
        let { accoutId, amount, dateTime, description } = values;
        // console.log("dateTime", dateTime);
        const date = moment(dateTime).toDate().getTime()
        // console.log("date", date);

        let _amount = Number(amount)
        if (TransactionType.PaidOl === (description as TransactionType) && _amount > 0) {
            _amount = -_amount
        }
        // console.log(date, dateTime);
        if (!accoutId) { return }
        try {
            const { res } = await new Promise<{ err, res }>((resolve, reject) => {
                dispatch(addTransaction({
                    hsUserId: accoutId!, amount: _amount, date, description, callback: (err, res) => {
                        err && reject(err)
                        resolve({ res, err })
                    }
                }));
            })
            handleClose()
            await SuccessDialog("Transaction Added", "New transaction successfully added.");
        } catch (error: any) {
            await ErrorDialog("Request Failed", error.errors[0].message);
        }
    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    return (
        <>
            <Button color='primary' size='small' onClick={handleClickOpen}  ><Grid container alignItems='center' ><Add /><span>Add Transaction</span></Grid></Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <HStransactionForm action="ADD" handleSubmit={handleSubmit} />
            </Dialog>
        </>
    )
}

const DeleteTransaction: FC<{ transaction_id: number }> = ({ transaction_id }) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    // const isDeletingTransaction = useAppSelector(s => s.sbqhPortal.fetchingStatus.deleteTransaction)
    const handleDelete = async () => {
        setLoading(true);
        const { isConfirmed } = await ConfirmationDialog({ title: "Are you sure?", message: "You won't be able to revert this!", confirmBtnText: "Yes, delete it!" })
        if (!isConfirmed) { setLoading(false); return }
        const { err } = await new Promise<{ err, res }>((resolve, reject) => {
            dispatch(deleteTransaction({
                transaction_id, callback: (err, res) => {
                    resolve({ err, res });
                }
            }))
        })
        // if (err) { setLoading(false); }
        setLoading(false)

    }

    return (
        <span style={{ position: "relative", bottom: 25 }} >
            {loading ?
                <CircularProgress style={{ position: "absolute" }} /> :
                <IconButton style={{ position: "absolute" }} title='Delete transaction' onClick={handleDelete} ><Delete /></IconButton>
            }
        </span>
    )
}
