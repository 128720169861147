import React, { FC, FormEvent, useEffect, useState } from "react";
import { ComparisonOperators, HSuserBasicInfo, QardanApplicant } from "../../../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes";
import { useAppDispatch, useAppSelector } from "../../../reduxStore/store";
import { addQardanApplicant, changeQardanApplicantDetails, deleteQardanApplicant, getAllQardanApplicants, } from "../../../reduxStore/slices/sbqhPortalSlice";
import { Grid, Typography, Dialog, TextField, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Add, Search } from '@material-ui/icons';
import { Button, Table } from "../..";
import QardanApplicantForm, { FormFields as QardanApplicantFormFields } from '../../Form/QardanApplicantForm';
// import { ErrorDialog, SuccessDialog } from "../../Alert";
import ApplicantDetailDialog from './ApplicantDetailDialog';
import { useDispatch } from "react-redux";
import { tableLoading } from "../HS_AccountsTab";

const searchFilters: { [P in keyof QardanApplicant]?: string } = {
    id: "ID",
    first_name: "First Name",
    last_name: "Last Name",
    its_no: "Its No"
}

const Qardan_ApplicantsTab: FC = ({ }) => {
    const { data, totalCount, filterOptions } = useAppSelector((state) => state.sbqhPortal.qardan_applicants_data)
    const isFetching = useAppSelector((state) => state.sbqhPortal.fetchingStatus.getAllQardanApplicants)
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [rowDetails, setRowDetails] = useState<QardanApplicant | null>(null);
    const [filterKey, setFilterKey] = useState('id');
    const dispatch = useAppDispatch();

    const loadData = async () => {
        dispatch(getAllQardanApplicants(filterOptions))
    }
    const handleChangePage = (pageNumber: number, pageSize: number) => {
        pageNumber++
        dispatch(getAllQardanApplicants({ ...filterOptions, pageNumber, pageSize }))
    }
    const handleSearchByFilter = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const elem = e.currentTarget.elements.item(0) as HTMLInputElement
        console.log(filterKey)
        const id = elem.value; //[filterKey]: elem.value
        const filterBy: any[] = []
        if (elem.value)
            filterBy.push(
                { field: filterKey, operator: ComparisonOperators.Contains, value: id }
            )

        dispatch(getAllQardanApplicants({ ...filterOptions, filterBy }))
    }
    const OpenApplicantDetailDialog = (values: QardanApplicant) => {
        setOpenDialog(true);
        setRowDetails(values)
    }

    useEffect(() => {
        !isFetching && !data && loadData()
    }, [])

    return (
        <Grid >
            <br />
            <Grid container justify='space-between' alignItems='center'>
                <Grid container item alignItems='center' xs={8} >
                    <form onSubmit={handleSearchByFilter} >
                        <Search />
                        <TextField name='search' title="Search" placeholder="Search" />
                    </form>
                    <Grid sm={3} item><TextField style={{ marginLeft: '5px' }} value={filterKey} onChange={(e) => { setFilterKey(e.target.value) }} variant="outlined" select type='select' size="small" label='Search By' fullWidth
                        children={Object.entries(searchFilters).map(([key, value], idx) => (
                            <MenuItem key={idx} value={key} >{value}</MenuItem>
                        ))}
                    /></Grid>
                </Grid>
                <AddApplicantForm />
            </Grid>
            <br />

            {rowDetails &&
                <ApplicantDetailDialog open={openDialog} handleClose={() => { setOpenDialog(false) }} applicantId={rowDetails.id} />}

            <Table
                label={<Typography variant='h4' align='center' >Qardan Hasana Applicants</Typography>}
                columns={[
                    { minWidth: 100, id: "id", align: "left", label: "File No", format: (val: string, rowValue) => <p style={{ cursor: "pointer", color: "purple", textDecoration: "underline" }} onClick={() => { OpenApplicantDetailDialog(rowValue) }} >{val.toUpperCase()}</p> },
                    { minWidth: 100, id: "title", align: "left", label: "Title", },
                    { minWidth: 200, id: "first_name", align: "left", label: "First Name", },
                    { minWidth: 200, id: "last_name", align: "left", label: "Last Name", },
                    { minWidth: 120, id: "its_no", align: "left", label: "ITS.No" },
                ]}
                rows={data || []}
                totalRows={totalCount}
                pageNumber={filterOptions.pageNumber - 1}
                onChangePageNo={handleChangePage}
                onChangeRowsPerPage={handleChangePage}
                dataFetchingAsync={true}
                loading={isFetching}
                tableStyle={{ height: ((isFetching && !data?.length) || !data?.length) ? undefined : 400, width: '100%' }}
                emptyRowMsg={isFetching ? tableLoading : <Grid  >
                    <br /><br /><br />
                    <Typography variant='h4' align='center' color='textSecondary' >There are no applicants to display</Typography>
                    <br /><br /><br />
                </Grid>}
            />
        </Grid>
    )
}

export default Qardan_ApplicantsTab;


const AddApplicantForm: FC = ({ }) => {

    const dispatch = useDispatch();
    const handleSubmit = async (values: QardanApplicantFormFields) => {
        console.log(values);
        const { its_no, id, last_name, first_name, email, phone_no, title, loanAllocation, company_name } = values;
        if (!id) { return }

        const { res } = await new Promise<{ res, err }>((resolve, reject) => {
            dispatch(addQardanApplicant({
                input: {
                    id, last_name, first_name, email, phone_no, title,
                    its_no: Number(its_no), company_name,
                    loanAllocation: {
                        amount: Number(loanAllocation.amount),
                        repayment_duration_in_months: Number(loanAllocation.repayment_duration_in_months),
                        date: loanAllocation.date.valueOf(), repayment_date: loanAllocation.repayment_date.valueOf()
                    }
                },
                callback: (err, res) => { resolve({ res, err }); }
            }))
        })
        res && handleClose()

    }

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    return (
        <>
            <Button color='primary' onClick={handleClickOpen}  ><Grid container alignItems='center' ><Add /><span>Add Applicant</span></Grid></Button>
            <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth style={{ zIndex: 1000 }} >
                <QardanApplicantForm action="ADD" handleSubmit={handleSubmit} />
            </Dialog>
        </>
    )
}