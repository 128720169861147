import axios, { AxiosError } from 'axios';
import { } from '../../../aws-cdk/lambda-fns/appsync-handlers/schemaTypes';
import { GeneratePDFconfig } from '../../../aws-cdk/lambda-fns/commonTypeDefs';
import { endpoints } from '../../app-config.json';
import { ErrorDialog } from '../components/Alert';
import { getCookie, stringZipper } from '../helper';



// type ResponseData = {
//    response?: BodyRes
//    error?: AxiosError<BodyRes>
// }


export const generatePdf = async <T = string>({ param, type }: GeneratePDFconfig<T>) => {
   const config = stringZipper(JSON.stringify({ param, type }), "zip");
   try {
      const apiEndpoint = endpoints['generate-pdf'] + `?Authorization=Bearer ${getCookie("sbqh_auth_token")}&config=${config}`;
      const resp = await axios.get(apiEndpoint, { responseType: 'blob' });
      console.log("res.data ===>", resp.data);
      const url = window.URL.createObjectURL(new Blob([resp.data], { type: "application/pdf" }));
      const link = document.createElement('a');
      link.href = url;
      link.target = "_blank"
      // link.setAttribute('download', `Transactions-Report.pdf`);
      document.body.appendChild(link);
      link.click();

   } catch (error) {
      console.log((error as any).response);
      return { error } as any
   }
}